define(
	// Define module dependencies
	[
		"backbone",
		"underscore",
		"shared/Ga",
		"gridguide",
		"shared/SiteHeader",
		"moment",
		"utils",
		"backbone.marionette",
		"marionette.templatecache",
	],

	function (Backbone, _, Ga, GridGuide, SiteHeader, moment, Utils, Marionette, TemplateCache) {
		"use strict";

		// add underscore mixin to allow use of moment.js in
		// underscore templates via `_.moment()`
		_.mixin({
			moment: moment,
			"$": $,
		});

		_.extend(Backbone.View.prototype, Marionette.Events);
		Marionette.CollectionView.setRenderer(TemplateCache.render);
		Marionette.View.setRenderer(TemplateCache.render);

		/**
		 * GlobalScript defines a set of functionality that all pages should be using.
		 * Modules that span every page should be instantiated here.
		 *
		 * @class GlobalScript
		 * @extends Backbone.View
		 */
		var GlobalScript = Backbone.View.extend( {
			el : $( document ), // Bind to global document

			events : {
				// JS-powered float label selector cached for consistent selecting
				"input .input-field.js-float-label" : "onJsFloatLabelChange",
				"focus .input-field.js-float-label" : "onJsFloatLabelFocus",
				"keydown .input-field.js-float-label" : "onJsFloatLabelFocus",
				"blur .input-field.js-float-label" : "onJsFloatLabelBlur",

				"blur .input-search-select" : "onMultiSelectBlur",
				"change .input-search-select" : "onMultiSelectChange",
				"focus .input-search-select" : "onMultiSelectFocus",

				// Listen for changes to hidden search select input values
				"change .hidden-search-select-input" : "onJsFloatLabelChange",
				"blur .hidden-search-select-input" : "onJsFloatLabelChange",
				"keyup .hidden-search-select-input" : "onJsFloatLabelChange",
			},

			initialize : function() {
				this.setVariables();

				this.setInputState();

				this.setAjaxLoadedInputState();

				// Initialize analytics on this page
				Ga.init();

				// localize all date elements
				Utils.localizeDateElements( ".local-datetime" );
			},

			setVariables : function() {
				this.setCsrf();

				this.gridGuide = new GridGuide();

				this.siteHeader = new SiteHeader( {
					shouldFix : this.options.shouldFix === undefined ? true : false
				} );
			},

			setCsrf : function () {
				// Set up AJAX CSRF
				var csrfParam = "",
					csrfValue = "",
					data = {};

				// Loop through meta tags, finding csrf tags
				$( "meta" ).each( function( i, el ) {
					if ( el.name === "csrf-param" ) {
						csrfParam = el.content;
					}

					if ( el.name === "csrf-token" ) {
						csrfValue = el.content;
					}
				} );

				// Set here to reference the param directly
				data[ csrfParam ] = csrfValue;

				// Set up jQuery's AJAX with CSRF data
				$.ajaxSetup( {
					data : data
				} );

				// Attach CSRF to window
				window.CSRF = data;
			},
			// End setter functions

			// Begin event listener functions
			onJsFloatLabelChange : function( e ) {
				var $el = $( e.currentTarget );

				$el.toggleClass( "valid", $el.val() !== "" );
			},

			onJsFloatLabelFocus : function( e ) {
				var $el = $( e.currentTarget );

				$el.addClass( "valid input-placeholder-hide" );
			},

			onJsFloatLabelBlur : function( e ) {
				var $el = $( e.currentTarget );

				$el.toggleClass( "valid", $el.val() !== "" );
				$el.removeClass( "input-placeholder-hide" );
			},

			onMultiSelectBlur : function( e ) {
				var $el = $( e.currentTarget );
				$el.find( "fieldset.editor-section-input" ).toggleClass( "float-active", $el.val() !== "" );
			},

			onMultiSelectChange : function( e ) {
				var $el = $( e.currentTarget ),
				titleField = $el.find( "input.search" );

				if ( !titleField.length ) {
					return;
				}

				$el.toggleClass( "valid", titleField.val().trim() !== "" );
			},

			onMultiSelectFocus : function( e ) {
				var $el = $( e.currentTarget );
				$el.find( "fieldset.editor-section-input" ).addClass( "float-active" );
			},
			// End event listener functions

			// Begin float label functions
			setInputState : function() {
				this.$( ".input-field.js-float-label" ).each( function() {
					// If the float label selector has a value set, toggle the valid class
					if ( $( this ).val() ) {
						$( this ).toggleClass( "valid" ).trigger( "input" );
					}
				} );
			},

			setAjaxLoadedInputState : function() {
				this.$( ".input-field.js-float-label.ajax-loaded" ).each( function() {
					// If the float label selector has a value set, toggle the valid class
					$( this ).toggleClass( "valid" ).trigger( "input" );
				} );
			}
			// End float label functions
		} );

		return GlobalScript;
	}
);
