define(

	// Define module dependencies
	[],

	function() {
		"use strict";

		var size = 4, // Size of blocks within the ID
		base = 36, // Mathematical base used when determining discrete values available
		discreteValues = Math.pow( base, size ), // Set discrete values
		windowKeyLength = Object.keys( window ).length;

		/**
		 * IdGenerator is used to generate collision-resistant IDs
		 *
		 * Useful for tagging multiple instances of a module that exist within the same containing script
		 *
		 * NOTE: Heavily influenced (but reduced to what the current interface needs) by Eric Elliott's CUID
		 *       https://github.com/ericelliott/cuid
		 *
		 * @class IdGenerator
		 * @typedef {object} IdGenerator
		 */
		return {
			// Prefix to prepend to all IDs
			prefix : "a",

			// Base value for counter
			// set as object to make this.count() testable without 36^4 length loops
			c : 0,

			/**
			 * generates a collision-resistant ID
			 *
			 * @return {string}     A collision-resistant ID
			 */
			generate : function() {
				// Timestamp, as a based-string
				var time = ( new Date().getTime() ).toString( base ),

				// String identifying the current browser
				// NOTE: Adds additional randomness for cases where multiple clients
				//       may shared instantiations of this module
				identifier = this.getIdentifier(),

				// Extra randomness from Math.random()
				random = this.randomBlock() + this.randomBlock(),

				// Padded counter
				padded = this.pad( this.count().toString( base ), size );

				return this.prefix + time + padded + identifier + random;
			},

			// Begin utility functions
				/**
				 * Optionally sets and then increments a counter,
				 * returning the value before the increment
				 *
				 * @return {integer}     Value representing the number of times an ID has been generated
				 */
				count : function() {
					// Reset counter if needed
					this.c = ( this.c < discreteValues ) ? this.c : 0;

					// Increment counter while returning it's previous value
					return ++this.c - 1;
				},

				/**
				 * Returns a string that's based on the current browser
				 * to add uniqueness across browsers to the ID generated
				 *
				 * @return {string}     A string that's based on the current browser
				 */
				getIdentifier : function() {
					// Form indentifying string
					var id = navigator.mimeTypes.length + navigator.userAgent.length + windowKeyLength;

					// Return padded identifier based on current client
					return this.pad( ( id ).toString( base ), size );
				},

				/**
				 * Takes in a number, converts it to a string, pads it with 0s on the left side
				 * and then retuns a sub string of a given length
				 * Used to pad counters and other ID-related parts
				 *
				 * @param  {integer} number     The number to be padded
				 * @param  {integer} size       The length of the string to return
				 *
				 * @return {string}             A padded string
				 */
				pad : function( number, size ) {
					// Cast number to string
					var str = "000000000" + number;
					return str.substr( str.length - size );
				},

				/**
				 * Leverages Math.random() to generate random blocks of characters
				 *
				 * @return {string}     A random block of characters for use as ID parts
				 */
				randomBlock : function() {
					// Return a random block of characters based on Math.random()
					// NOTE: Bitwise shift added to floor randomized value
					//       http://jsperf.com/rounding-numbers-down
					return this.pad( (Math.random() * discreteValues << 0 ).toString( base ), size );
				},
			// End utility functions
		};
	}
);
