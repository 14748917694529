define(

	// Define module dependencies
	[
		"backbone",
		"underscore",
	],

	function ( Backbone, _ ) {
		"use strict";

		/**
		 * SaveStatusBar defines a set of functionality that will be used for
		 * the save status bar.
		 *
		 * @class SaveStatusBar
		 * @extends Backbone.View
		 */
		return Backbone.View.extend( {
			el : ".save-status-bar",

			initialize : function() {
				this.setVariables();
			},

			// Begin setter functions
			setVariables : function() {
				// Element vars
				this.message = this.$( "p" );

				// set default option values
				this.overrideDefaults( {
					// Class to use when showing element
					shownClass: "shown",
					// Class to use for default alert messages
					alertClass: "alert",
					// Class to use for error messages
					errorClass: "error",
					// Class to use for success messages
					successClass: "success",
					// Class to use for warning messages
					warningClass: "warning",
					// Class to use when scheduling
					schedulingClass: "scheduling",
					// Class to use to indicate progress bar
					progressBarClass: "progress",
					// Animation time (in milliseconds)
					animationTime: 300,
					// Whether to delay hiding the element or not
					delayBeforeHide: true,
					// Time to delay before hiding the element (in milliseconds)
					delayBeforeHideTime: 2000,
				} );
			},

			// End setter functions

			// Begin public API functions
				/**
				 * Shows the save status bar, passing optional options
				 *
				 * @example
				 * this.saveStatusBar.show( "Bar" );
				 * this.saveStatusBar.show( "Foo", { type: "error" } );
				 *
				 * @param {string} message      Message to be used by the save status bar
				 * @param {object} [options={}] Options to customize the save status bar
				 *
				 * @return {Backbone.View}      A reference to `this` to allow for chaining
				 */
				show : function( message, options ) {
					// Update the message and error class
					this.update( message, options );

					// Add show class to element
					this.$el.addClass( this.shownClass );

					return this;
				},

				/**
				 * Alias for #show() that defaults to show a success message
				 *
				 * @example
				 * this.saveStatusBar.success( "Content saved!" );
				 *
				 * @param {string} message  Message to be used by the save status bar
				 * @return {Backbone.View}  A reference to `this` to allow for chaining
				 */
				success : function( message ) {
					return this.show( message, { type: "success" } );
				},

				/**
				 * Alias for #show() that defaults to show an error
				 *
				 * @example
				 * this.saveStatusBar.error( "Content can't be saved!" );
				 *
				 * @param {string} message  Message to be used by the save status bar
				 * @return {Backbone.View}  A reference to `this` to allow for chaining
				 */
				error : function( message ) {
					return this.show( message, { type: "error" } );
				},

				/**
				 * Alias for #show() that defaults to show a warning message.
				 *
				 * @example
				 * this.saveStatusBar.warn( "You need to save content in order for crop changes to save." );
				 *
				 * @param {string} message  Message to be used by the save status bar
				 * @return {Backbone.View}  A reference to `this` to allow for chaining
				 */
				warning : function( message ) {
					return this.show( message, { type: "warning" } );
				},

				/**
				 * Hides the save status bar, optionally delaying before hiding for a
				 * pre-defined time
				 *
				 * @example
				 * this.saveStatusBar.hide();
				 * this.saveStatusBar.hide( function() {
				 *     console.log( "Save status bar is hidden" );
				 * } );
				 *
				 * @param {Function} callback Callback function to be called after bar is hidden
				 *
				 * @return {Backbone.View}    A reference to `this` to allow for chaining
				 */
				hide : function( callback ) {
					// Set default callback if needed
					callback = callback === undefined ? function(){} : callback;

					if ( !this.delayBeforeHide ) {
						this.resetState( true, callback );
						return this;
					}

					// Check if this component should wait before hiding
					setTimeout( this.resetState.bind( this, false, callback ), this.delayBeforeHideTime );

					return this;
				},

				/**
				 * Updates the save status bar, optionally setting an error class
				 *
				 * @param {string} message  Message to be used by the save status bar
				 * @param {object} [options={}]  Options to customize the save status bar
				 * @param {string} [options.type="alert"]  Type of message to be displayed (alert|success|error|warning|schedule|progress)
				 *
				 * @example
				 * this.saveStatusBar.update( "Bar" );
				 * this.saveStatusBar.update( "Foo", { type: "error" } );
				 */
				update : function( message, options ) {
					options = _.extend( {}, this.getDefaultOptions(), options );

					// add class based on message type
					this.$el
						// remove all "type" classes
						.removeClass( [
							this.alertClass,
							this.successClass,
							this.errorClass,
							this.warningClass,
							this.progressBarClass,
							this.schedulingClass
						].join( " " ) )
						// add specific type class
						.addClass( options.type || "" );

					// Update status message
					this.message.html( message );

					return this;
				},
			// End public API functions

			// Begin utility functions
				/**
				 * Get default options for customizing the display of
				 * the save status bar.
				 *
				 * @return {object} Default options object.
				 */
				getDefaultOptions : function() {
					return {
						type: "alert",
					};
				},

				/**
				 * Resets the state of the save status bar, optionally waiting
				 * a pre-defined amount of time before doing so
				 *
				 * NOTE: Not meant to be called by external consumers
				 *
				 * @param  {boolean} immediate 	Boolean indicating if state should be reset immediately or not
				 * @param  {Function} callback  Callback function to be called after state is reset
				 */
				resetState : function( immediate, callback ) {
					// Remove show class
					this.$el.removeClass( "shown" );

					// Check if this component should immediately remove an status classes
					// Optionally wait for any animations to complete, then remove an status classes
					setTimeout( function() {
						this.$el.removeClass( this.errorClass );

						// Call callback, keeping the `this` content
						callback.call( this );
					}.bind( this ), immediate ? 0 : this.animationTime );
				},
			// End utility functions
		} );
	}
);
