define(

	// Define module dependencies
	[
		"backbone",
		"shared/FixedElement",
		"utils"
	],

	function ( Backbone, FixedElement, Utils ) {
		"use strict";

		/**
		 * SiteHeader defines a set of functionality that will be used in the editorial interface's main header/navigation
		 *
		 * @class SiteHeader
		 * @extends Backbone.View
		 */
		var SiteHeader = Backbone.View.extend( {
			el : ".site-header" ,

			events : {
				// User info/drop down events
				"click .mobile-nav-user-info-button" : "onUserInfoToggleClicked",
				"click .icon-arrow-down" : "onUserInfoArrowClicked",
				"click .user-location" : "onUserLocationClicked",

				// Mobile nav events
				"click .mobile-nav-menu-button" : "onMenuToggleClicked",
				"click .sub-nav-item" : "onMobileSubNavItemClicked",
				"click .sub-nav-back-button" : "onMobileBackButtonClicked",
				"click .nav-item" : "onNavItemClick"
			},

			initialize : function() {
				this.setVariables();

				this.setListeners();
			},

			// Begin setter functions
			setVariables : function() {
				// Window vars
				this.window = $( window );
				this.html = $( "html" );
				this.body = $( "body" );

				if ( this.options.shouldFix !== undefined && !!this.options.shouldFix ) {
					this.fixedElement = new FixedElement( {
						element: this.$el,
						fixedClass: "site-header-fixed",
					} );
				}

				// Buttons
				this.mobileNavUserButton = this.$( ".mobile-nav-user-info-button" );
				this.mobileNavMenuButton = this.$( ".mobile-nav-menu-button" );

				// Menus
				this.siteHeaderMenus = this.$( ".site-header-menus" );
				this.navMenu = this.$( ".nav-menu" );
				this.userDropDownMenu = this.$( ".user-dropdown-menu" );

				// User drop down
				this.userDropDownLocalesList = this.userDropDownMenu.find( ".locales-list" );

				// Used to calculate the subnav, in case it is larger
				this.navOriginalMenuHeight = 0;

				this.parentNavItems = this.$( ".nav-item" );
				this.subNavs = this.$( ".sub-nav-items-container" );

				// Divs that, when clicked, do not close the mobile nav
				this.bodyClickExcludes = [
					".site-header .mobile-nav-user-info-button",
					".site-header .mobile-nav-menu-button",
					".site-header .nav-item",
					".site-menu .sub-nav-items-container",
					".site-header .sub-nav-item",
					".site-header .icon-arrow-down",
					".site-header .user-dropdown-menu"
				];
			},

			setListeners : function() {
				this.body.on( "click", this.onBodyClick.bind( this ) );

				this.window.on( "resize", this.onWindowResize.bind( this ) );
			},
			// End setter functions

			// Begin window functions
			onBodyClick : function( e ) {
				// Check if we should exclude the event from resetting all of the menus
				if ( Utils.shouldStop( e, this.bodyClickExcludes ) ) {
					return;
				}

				this.resetAllMenus();
			},

			onWindowResize : function() {
				this.resetAllMenus();
			},
			// End window functions

			// Begin event listener functions
			onMenuToggleClicked : function() {
				// Check if the menu should be shown after resetting all menus
				var toShow = !this.mobileNavMenuButton.hasClass( "crossed" );

				this.resetAllMenus();

				// Check if more work is required
				if ( !toShow ) {
					return;
				}

				this.showNavMenu();
			},

			onMobileSubNavItemClicked : function( e ) {
				// Set which nav item was clicked and get the parent of the clicked link
				var linkParent = $( e.target );

				// If the clicked item is not a label for one of the parent nav items
				if ( linkParent.not( ".sub-nav-back-button" ) ) {
					// Just treat this like a normal link, ie. dont bubble up any click events to parents who may be listening
					e.stopPropagation();
				}
			},

			onNavItemClick : function( e ) {
				// Mobile
				if ( this.menuIsIn() ) {
					// Set which nav item was clicked and get the parent of the clicked link
					var linkParent = $( e.target ).parent(),

					// Cache the sub nav ( child )  of the link that was clicked
					selectedSubNav = linkParent.find( ".sub-nav-items-container" );

					// If the clicked link is in a sub-nav-item, or the link is already selected, just exit out
					if ( ( linkParent.hasClass( "sub-nav-item" ) && linkParent.not(  ".sub-nav-parent-item" ) ) || linkParent.hasClass( "selected" ) ) {
						return;
					}

					// Prevent links
					Utils.preventEventActions( e );

					this.subNavs.addClass( "unselected" );

					// Adjust the heights of the nav and sub navs according to which is larger
					this.adjustMenuHeights( selectedSubNav );

					// For the parent nav items, remove selected, add unselected
					this.parentNavItems.removeClass( "selected" ).addClass( "unselected" );

					// Now add selected to the parent
					linkParent.removeClass( "unselected" ).addClass( "selected" );
				// Desktop
				} else {
					// Prevent going to any top-level parent links
					Utils.preventEventActions( e );
				}
			},

			onMobileBackButtonClicked : function( e ) {
				e.preventDefault();

				this.navMenu.height( this.navOriginalMenuHeight );

				this.resetMobileNavClasses();
			},

			onUserInfoArrowClicked : function() {
				this.userDropDownMenu.toggleClass( "active" );
				this.userDropDownMenu.removeClass( "closed" );

				// Check if menus need reset ( like the locales list )
				if ( !this.userDropDownMenu.hasClass( "active" ) ) {
					this.resetAllMenus();
					this.userDropDownMenu.addClass( "closed" );
				}
			},

			onUserInfoToggleClicked : function() {
				// Check if the menu should be shown after resetting all menus
				var toShow = !this.mobileNavUserButton.hasClass( "active" );

				this.resetAllMenus();

				// Check if more work is required
				if ( !toShow ) {
					return;
				}

				this.showUserMenu();
			},

			onUserLocationClicked : function() {
				if ( this.userDropDownLocalesList.length ) {
					this.userDropDownLocalesList.toggleClass( "active" );
				}
			},
			// End event listener functions

			// Begin utility functions
			adjustMenuHeights : function( selectedSubNavMenu ){
				// Get the height of the selected sub nav item
				var selectedSubNavHeight = selectedSubNavMenu.height();

				// If the original height of the nav is more or equal to the sub nav
				if ( this.navOriginalMenuHeight >= selectedSubNavHeight ) {
					// Set the sub Nav height to match
					selectedSubNavMenu.height( this.navOriginalMenuHeight );
				} else {
					// The sub nav is larger. Adjust the main nav menu so we can see all the items
					this.navMenu.height( selectedSubNavHeight );
				}
			},

			menuIsIn : function() {
				return this.mobileNavUserButton.hasClass( "active" ) || this.mobileNavMenuButton.hasClass( "crossed" );
			},

			resetAllMenus : function() {
				// User info/drop down classes
				this.mobileNavUserButton.removeClass( "active" );
				this.userDropDownMenu.removeClass( "active" );
				this.userDropDownLocalesList.removeClass( "active" );

				// Mobile nav classes
				this.mobileNavMenuButton.removeClass( "crossed" );
				this.siteHeaderMenus.removeClass( "active" );
				this.navMenu.removeClass( "active" );

				this.resetMobileNavClasses();
			},

			resetMobileNavClasses : function() {
				this.subNavs.removeClass( "selected unselected" );
				this.parentNavItems.removeClass( "selected unselected" );

				// Make sure to reset any hard-coded heights set in `adjustMenuHeights`
				this.subNavs.css( "height", "" );
			},

			showNavMenu : function() {
				this.subNavs.addClass( "unselected" );

				this.mobileNavMenuButton.toggleClass( "crossed" );

				this.siteHeaderMenus.toggleClass( "active" );

				this.navMenu.toggleClass( "active" );

				// Cache the nav menu's original height in px for use later
				this.navOriginalMenuHeight = this.navMenu.height();

				// Silly, but now explicitly set this height so any initial animations on any sub navs that are larger, run
				this.navMenu.height( this.navOriginalMenuHeight );
			},

			showUserMenu : function() {
				this.mobileNavUserButton.addClass( "active" );

				this.userDropDownMenu.addClass( "active" );
			}
			// End utility functions
		} );

		return SiteHeader;
	}
);
