define(
	[
		"underscore",
		"backbone"
	],

	function( _, Backbone) {
		"use strict";

		/**
		 * PaginatorTotals is a shared module that can be used for handling displaying the pagination totals
		 * Example: Displaying 1 - 48 of 2000
		 *
		 * The Paginator module works by passing in the current page number. PaginatorTotals update can be called
		 * in the same way to update the totals. This module expects the total number of items and the number of
		 * items allowed per page to be data attributes on the pagination-totals container.
		 *
		 * data-items-per-page={{ itemsPerPage }} is the number of items displayed per page
		 * data-total-items={{ totalItems }} is the total number of items being paginated
		 *
		 * The template used to display the pagination totals is:
		 * views/widgets/listing-page/pagination/totals.twig
		 *
		 * @class PaginatorTotals
		 * @extends Backbone.View
		 */
		return Backbone.View.extend( {
			el: ".pagination-totals",

			initialize: function() {
				this.setVariables();
			},

			setVariables: function() {
				// Number of items displayed per page
				this.itemsPerPage = parseInt( this.options.itemsPerPage || this.$el.data( "items-per-page" ), 10 );

				// Total number of items being paginated
				this.totalItems = parseInt( this.options.totalItems || this.$el.data( "total-items" ), 10 );

				// Startpoint dom element
				this.$startpoint = this.$el.find( ".startpoint" );

				// Endpoint dom element
				this.$endpoint = this.$el.find( ".endpoint" );

				// Total items dom element
				this.$totalItems = this.$el.find( ".total-items" );
			},

			/**
			 * Update triggers the change to the pagination totals template based on the current page
			 *
			 * The pagination totals are diplayed in the format:
			 * Displaying [startpoint] - [endpoint] of [totalItems]
			 *
			 * @param page Current pagination page
			 * @param totalCount Total number of items to paginate
			 */
			update: function( page, totalCount ) {
				// If the totalCount is 0 or less than 1, we want hide the pagination totals
				if ( ( typeof totalCount !== "undefined" ) && ( totalCount < 1 ) ) {
					this.$el.hide();

					return;
				}

				// If the totalCount is greater than 0, we need to show the pagination totals
				// They could be hidden from a previous search.
				this.$el.show();

				// Current page multiplied by the items per page will tell us our endpoint
				var end = parseInt( page, 10 ) * this.itemsPerPage,
				// The endpoint minus the items per page plus one will tell us our starting point
				start = end - this.itemsPerPage + 1;

				this.totalItems = parseInt( totalCount ? totalCount : this.totalItems, 10 );

				// Update the start point
				this.$startpoint.html( start.toLocaleString() );

				// Update the endpoint.
				// If the endpoint is larger than the total number of items display the total number of items instead.
				this.$endpoint.html( ( end > this.totalItems ) ? this.totalItems.toLocaleString() : end.toLocaleString() );

				// Update the total items
				this.$totalItems.html( this.totalItems.toLocaleString() );
			}
		} );
	}
);
