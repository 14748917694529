define(
	// Define module dependencies
	[
		"backbone",
		"underscore",
		"models/GenericModelView",
		"shared/IdGenerator",
		"logger",
		"mediaqueryevent",
		"shared/Paginator",
		"shared/PaginatorTotals",
		"shared/Ajaxer",
		"shared/DateTimePicker",
		"utils",
		"backbone.radio",
	],

	function( Backbone, _, GenericModelView, IdGenerator, Logger, MediaQueryEvent, Paginator, PaginatorTotals, Ajaxer, DateTimePicker, Utils  ) {
		"use strict";

		/**
		 * @class ListingPage
		 * @extends Backbone.View
		 */
		return Backbone.View.extend( {
			el : ".listing-page",

			events : {
				// Search filter events
				"focus .input-search .input-field" : "onInputFocus",
				"blur .input-search .input-field" : "onInputBlur",
				"keydown .input-search .input-field" : "onInputKeydown",
				"change .search-box-options input[type='hidden']" : "onAdvancedFilterChanged",

				// Basic filter events
				"click .basic-filters .filter" : "onBasicFilterClicked",

				// Advanced search events
				"click .advanced-search-toggle" : "onAdvancedSearchToggleClicked",
				"click .apply-filters" : "onApplyFiltersClicked",
				"click .save-filters" : "onApplyFiltersClicked",
				"click .icon-search" : "onApplyFiltersClicked",
				"click .reset-filters" : "resetAdvancedFilters",

				// Advanced filter events
				"change .advanced-filters select" : "onAdvancedFilterChanged",
				"change .advanced-filters input[ type='checkbox' ]" : "onAdvancedFilterChanged",
				"focus .advanced-filters input[type='text']" : "onInputFocus",
				"blur .advanced-filters input[type='text']" : "onInputBlur",
				"change .advanced-filters input[type='date']" : "onInputBlur",
				"change .advanced-filters input[type='hidden']" : "onAdvancedFilterChanged",
				"change .advanced-filters input[type='radio']" : "onAdvancedFilterChanged",
				"keydown .advanced-filters input[type='text']" : "onAdvancedFilterInputKeydown",
				"change .input-date-picker input[type='text']" : "onAdvancedFilterChanged",

				// Advanced filter control events
				"click .advanced-filters .back" : "onAdvancedFilterBackClicked",
				"click .advanced-filters .reset" : "onAdvancedFilterResetClicked",

				// Table drop down events
				"change .table-drop-down select" : "onTableDropDownChanged"
			},

			initialize : function() {

				this.setElements();

				this.setVariables();

				this.setListeners();

				// Set up media query variables
				this.setMediaQueryVariables();

				// Select All filter by default
				this.selectDefaultBasicFilter();

				// Set up search variables
				this.setSearchVariables();

				// Set up text input values for filters
				this.setTextInputVals();

				// Set collection items
				this.setItems();

				this.startDatePicker = new DateTimePicker({ el: this.$( "#start-date-picker" ) });
				this.endDatePicker = new DateTimePicker({ el: this.$( "#end-date-picker" ) });
				this.startDatePicker
					.on( "change", this.endDatePicker.setMinDate, this.endDatePicker )
					.trigger( "triggerChange" );
				this.endDatePicker
					.on( "change", this.startDatePicker.setMaxDate, this.startDatePicker )
					.trigger( "triggerChange" );

			},

			// Begin setter functions
			setVariables : function() {
				// Text input vars
				this.textInputVals = {};

				// Set a unique ID for this paginator view
				this.id = IdGenerator.generate();

				// Instantiate logger
				this.logger = new Logger( "Listing Page" );

				// Instantiate pagination
				this.paginator = new Paginator( {
					el : this.$( ".pagination-wrapper" )
				} );

				// Page override used to allow for page changes from paginator without changing filters
				this.pageOverride = false;

				// Whether to prepend or append new models
				this.prependItems = false;

				// Class applied to elements to indicate "active" state
				this.activeClass = this.options.activeClass || "active";

				// Class applied to items container to indicate a "no-items" state
				this.noItemsClass = this.options.noItemsClass || "no-items";

				// Class applied to items container to indicate a "loading" state
				this.loadingClass = this.options.loadingClass || "loading";

				// Class applied to elements to indicate a "selected" state
				this.selectedClass = this.options.selectedClass || "selected";

				// Class applied to elements to indicate a "valid" state
				this.validClass = this.options.validClass || "valid";

				// Set selector to use for item view templates
				this.itemViewTemplateSelector = this.options.itemViewTemplateSelector || "#generic-item-template";

				// Set boolean indicating if responses should be wrapped or not
				this.wrapResponses = this.options.wrapResponses !== undefined ? !!this.options.wrapResponses : true;

				// Set model to be used when rendering items
				this.itemModel = this.options.itemModel || GenericModelView.prototype.ModelTemplate;

				// Set view to be used when rendering items
				this.itemView = this.options.itemView || GenericModelView;

				// Set collection
				this.collection = new Backbone.Collection( [], { model : this.itemModel } );

				// Set up radio channel
				this.channel = Backbone.Radio.channel( Utils.namespaceString( "widgets/ListingPage", this.id ) );

				// Get breakpoint, under which modal should be used
				this.modalBreakpoint = this.options.modalBreakpoint !== undefined ? ( ~~this.options.modalBreakpoint || 1000 ) : 1000;

				// Speed to scroll an element with an animation
				this.scrollSpeed = this.options.scrollSpeed !== undefined ? ~~this.options.scrollSpeed : 250;

				// Whether scrolling an element during search is enabled. Default to false
				this.scrollOnSearch = this.options.scrollOnSearch === true;

				// Whether support for hierarchical select is enable. Default to false
				this.enableHierarchicalSelect = this.options.enableHierarchicalSelect !== undefined ? this.options.enableHierarchicalSelect : false;

				// Instantiate pagination totals
				this.paginatorTotals = new PaginatorTotals( {
					el: this.$( ".pagination-totals" )
				} );

				// Initialize input values extractors
				this.inputValueHelper = {
					select: this.getSelectValue.bind( this ),
					checkbox: this.getCheckboxValue.bind( this ),
					radio: this.getRadioValue.bind( this ),
					date: this.getDateValue.bind( this ),
					default : this.getDefaultInputValue.bind( this )
				};

				// set up the editFormView channel
				this.editChannel = Backbone.Radio.channel( "editFormView" );
			},

			setListeners : function() {
				this.window.on( "resize", this.onWindowResize.bind( this ) );

				// Listen to collection events
				this.listenTo( this.collection, "reset", this.collectionReset );
				this.listenTo( this.collection, "add", this.collectionAdd );
				this.listenTo( this.collection, "remove", this.collectionRemove );

				// Listen to pagination events
				this.paginator.channel.on( "pageChanged", this.onPageChanged.bind( this ) );

				// Listen for externally-triggered search events
				this.on( "search", this.onSearchTriggered.bind( this ) );
			},

			setElements : function() {
				// Window vars
				this.window = $( window );
				this.body = $( "body" );
				this.html = $( "html, body");

				// Search vars
				this.searchContainer = this.$( ".search-container" );
				this.searchInput = this.searchContainer.find( ".input-field" );

				// Search type
				this.searchInputType = this.searchContainer.find( ".input-field-type" );

				// Advanced search
				this.advancedSearchContainer = this.$( ".advanced-search-toggles" );

				// Filter containers
				this.basicFiltersContainer = this.$( ".basic-filters" );
				this.advancedFiltersContainer = this.$( ".advanced-filters" );

				// Filters
				this.basicFilters = this.basicFiltersContainer.find( ".filter" );
				this.advancedFilters = this.advancedFiltersContainer
					.find( "fieldset, .editor-section-input" );
				this.advancedFiltersSearch = this.$( ".input-search-select" );

				// Selects
				this.advancedFilterSelects = this.advancedFilters.find( "select" );

				// Table
				this.table = this.$( ".listing-page-table" );

				// Table headers
				this.tableHeaderContainer = this.$( ".table-header ul" );
				this.tableHeaders = this.tableHeaderContainer.find( ".header" );

				// Table drop down
				this.tableDropDown = this.$( ".table-drop-down select" );

				// Items
				this.itemsContainer = this.options.itemsContainer || this.$( ".items-container" );
				this.items = this.itemsContainer.find( this.options.itemSelector || ".item" );

				// Scrollable element
				this.scrollElement = this.options.scrollElement || this.html;
			},

			setMediaQueryVariables : function() {
				// Set default current breakpoint
				this.currentBreakpoint = 0;

				// Listen for breakpoints
				// NOTE: This is here to set up listeners before instantiating the broadcaster
				Backbone.Radio.channel( "mediaquery" ).on( "breakpoint", this.onBreakpoint.bind( this ) );

				// Set up media query event
				this.mediaQueryEvent = new MediaQueryEvent();
			},

			selectDefaultBasicFilter : function() {
				// Turn 'all' filter on
				this.basicFilters.filter( "[data-id='all']" ).addClass( this.activeClass );
			},

			setSearchVariables : function() {
				// Resource to be used when forming search URL
				this.searchResource = this.options.searchResource || "invalid";

				// Endpoint to use when performing searches
				this.searchEndpoint = this.options.searchEndpoint || "/" + window.URL_LANGUAGE_PREFIX + "/ajax/search";

				// Boolean attribute indicating if a search is currently occurring
				this.searching = false;

				// Boolean indicating if a re-search should occur
				// NOTE: This is used when a user changes settings but a search is already in flight
				this.reSearch = false;
			},

			setTextInputVals : function() {
				// Loop through advanced filters and search input to set initial values
				this.advancedFilters.add( this.searchContainer ).find( "input[type='text']" ).each( function( i, el ) {
					this.textInputVals[ el.id ] = el.value;
				}.bind( this ) );
			},
			// End setter functions

			// Begin collection functions
			collectionReset : function() {
				this.collection.each( this.collectionAdd, this );

				// Check collection length
				this.checkCollectionLength();
			},

			collectionAdd : function( model ) {
				// Set up item view
				var view = new this.itemView( {
					model : model,
					parent : this,
					templateSelector : this.itemViewTemplateSelector,
				} );

				// Log view creation
				this.logger.log( "Creating model view." );

				// Append or prepend item to container
				if ( !model.get( "_el" ) ) {
					this.itemsContainer[ this.prependItems ? "prepend" : "append" ]( view.render().el );
				}

				// Add view listeners
				view.on( "viewClicked", this.onViewClicked.bind( this, view ) );
				view.on( "deletedClicked", this.onViewDeleteClicked.bind( this, view ) );

				// Check collection length
				this.checkCollectionLength();

				// Return view so extending classes can use it
				return view;
			},

			collectionRemove : function() {
				// Log model removal
				this.logger.log( "Removed model from collection" );

				// Check collection length
				this.checkCollectionLength();
			},

			checkCollectionLength : function() {
				// Log length check
				this.logger.log( "Checking collection length: ", this.collection.length );

				// Add/remove no items class based on collection length
				this.itemsContainer.toggleClass( this.noItemsClass, this.collection.length === 0 );
			},
			// End collection functions

			// Begin window functions
			onWindowResize : function() {
				// Reset state of this module
				this.resetState();
			},
			// End window functions

			// Begin event listener functions
			onInputBlur : function( e ) {
				// Check if current value is different from when the field was focussed
				if ( this.textInputVals[ e.target.id ] !== e.target.value ) {
					this.handleChange();
				}
			},

			onInputFocus : function( e ) {
				// Set value when the field is focussed
				this.textInputVals[ e.target.id ] = e.target.value;
			},

			onInputKeydown : function( e ) {
				// Check for enter key
				if ( e.keyCode === 13 ) {
					// Log enter key press
					this.logger.log( "Enter pressed" );

					// Prevent form submission
					Utils.preventEventActions( e );

					// Reset text input value
					this.textInputVals[ e.target.id ] = e.target.value;

					// Handle change
					this.handleChange();

					return;
				}
			},

			onAdvancedFilterInputKeydown : function( e ) {
				// Check for enter key
				if ( e.keyCode === 13 ) {
					// Log enter key press
					this.logger.log( "Enter pressed (on advanced filter)" );

					// Reset advanced filters settings
					this.onAdvancedSearchToggleClicked();

					// Call standard input key down method
					this.onInputKeydown( e );
				}
			},

			onAdvancedFilterChanged : function() {
				// Log filter change
				this.logger.log( "Advanced filter changed" );

				// Check if modal is in use (should not trigger a change)
				if ( this.shouldUseModal() ) {
					return;
				}

				// Handle change as normal
				this.handleChange();
			},

			onAdvancedFilterBackClicked : function() {
				// Log click event
				this.logger.log( "Advanced filter back clicked" );

				// Reset state of this module
				this.resetState();
			},

			onAdvancedFilterResetClicked : function() {
				// Log click event
				this.logger.log( "Advanced filter reset clicked" );

				// Reset advanced filters
				this.resetAdvancedFilters();
			},

			onAdvancedSearchToggleClicked : function() {
				// Log click event
				this.logger.log( "Advanced filter toggle clicked" );

				// Toggle advanced search variables
				this.advancedSearchContainer.toggleClass( this.activeClass );
				this.advancedFiltersContainer.toggleClass( this.activeClass );
			},

			onApplyFiltersClicked : function() {
				// Reset advanced filters settings
				this.onAdvancedSearchToggleClicked();

				// Things have changed, need to reset items
				this.handleChange();
			},

			onBasicFilterClicked : function ( e ) {
				var target = $( e.target ),
				$filterAll = this.basicFilters.filter( "[data-id='all']" ),
				filterId = target.attr( "data-id" ) && target.attr( "data-id" ).length ?
					target.attr( "data-id" ).trim() : null;

				// Check for filter ID
				if ( filterId === null ) {
					return;
				}

				// Log click event
				this.logger.log( "Basic filter clicked." );

				// Check if the "all" filter was clicked
				if ( filterId === "all" ) {
					// Turn all filters off as needed
					this.basicFilters.removeClass( this.activeClass );

					// Turn 'all' filter on
					target.addClass( this.activeClass );
				} else {
					// Toggle filter's active class
					target.toggleClass( this.activeClass );

					// Remove active class on 'all' filter
					$filterAll.removeClass( this.activeClass );
				}

				// Things have changed, need to reset items
				this.handleChange();
			},

			onTableDropDownChanged : function() {
				// Get selected option
				var selected = this.tableDropDown.find( ":selected" ),
				id;

				// Check for selected option
				if ( !selected.length ) {
					return;
				}

				// Cache option's value
				id = selected[0].value;

				// Log change event
				this.logger.log( "Table dropdown changed with ID: ", id );

				// Loop through all items
				this.items.each( function( i, el ) {
					var $el = $( el ),
					props = $el.find( ".item-prop" );

					// Loop through all item properties
					props.each( function( j, prop ) {
						var $prop = $( prop );

						// Toggle selected class based on class matching on option's value
						$prop.toggleClass( "selected", $prop.hasClass( id ) );
					}.bind( this ) );
				}.bind( this ) );
			},
			// End event listener functions

			// Begin view event functions
			onViewClicked : function( view, e ) {
				// Announce view click to channel
				Utils.sendChannelMessage( this.channel, "viewClicked", view, e );
			},

			onViewDeleteClicked : function( view, e ) {
				// Announce view delete click to channel
				Utils.sendChannelMessage( this.channel, "viewDeletedClicked", view, e );

				// Remove model from collection
				this.collection.remove( view.model );

				// Trigger a destroy event on the model to review the view
				view.model.trigger( "destroy" );
			},
			// End view event functions

			// Begin pagination functions
			onPageChanged : function( page ) {
				// Log change event
				this.logger.log( "Page changed." );

				// Set page override value
				this.pageOverride = ~~page || 1;

				// Call for a change
				this.handleChange();
			},
			// End pagination functions

			// Begin breakpoint functions
			onBreakpoint : function( bp ) {
				this.currentBreakpoint = Utils.parseBreakpoint( bp );
			},
			// End breakpoint functions

			// Begin searching functions
			onSearchTriggered : function() {
				// Log trigger
				this.logger.log( "Search triggered" );

				// Call for a change
				this.handleChange();
			},

			handleChange : function() {
				// Get default search body as well as all active basic
				// filter IDs
				var searchBody = this.getDefaultSearchBody(),
				activeBasicFilterIds = this.getActiveBasicFilterIds();

				// Check for page override
				if ( this.pageOverride !== false ) {
					searchBody.page = this.pageOverride;
				}

				// Add active basic filter IDs to search body
				activeBasicFilterIds.forEach( function( id ) {
					// Don't add "all" filter
					if ( id === "all" ) {
						return;
					}

					// NOTE: 1 indicates an active filter when forming search params within PHP form models
					searchBody.basicFilters[ id ] = 1;
				}.bind( this ) );

				// Add advanced filters to search body
				this.advancedFilters.each( function( i, el ) {
					var $el = $( el ),
					input = $el.find( "input, select" ),
					name = input.length ? input.attr( "name" ) : "invalid",
					inputType = input.is( "select" ) ? "select" : input.attr( "type" );

					// user parent group name for widgets with multiples input names
					if ( $el && $el.data && $el.data( "input-name" ) ) {
						name = $el.data( "input-name" );
					}

					searchBody.advancedFilters[ name ] = this.inputValueHelper[ inputType ] ? this.inputValueHelper[ inputType ]( input ) : this.inputValueHelper[ "default" ]( input );
				}.bind( this ) );

				this.updateEndDate( searchBody );

				// Log search body
				this.logger.log( "Handling search. Search body formed." );

				// Check if element should be scrolled
				if ( this.scrollOnSearch ) {
					// Scroll element
					this.scrollEl();
				}

				// Perform search
				this.doSearch( searchBody );
			},

			// This is a workaround to override this assignment in other places, given
			// that this is widely used
			updateEndDate : function() {
				//Leaves the end date in the format that is common for all uses.
				var advancedFilters = { "end-date-date" : $( "#end-date" ).val() + "T23:59:59.999Z" };

				return advancedFilters;
			},

			doSearch : function( searchBody ) {
				// Check if a search is currently occurring
				if ( this.searching ) {
					// Set flag to re-search after the current search finishes
					this.reSearch = true;

					return;
				}

				// Set searching value
				this.searching = true;

				// Form form data
				var data = new FormData();

				// Set search body attributes in form data
				Object.keys( searchBody ).forEach( function( key ) {
					// Stringify objects as needed
					data.append( key, _.isObject( searchBody[ key ] ) ? JSON.stringify( searchBody[ key ] ) : searchBody[ key ] );
				}.bind( this ) );

				// Add CSRF, since formdata overrides ajax data
				data.append( Object.keys( window.CSRF ).shift(), window.CSRF[ Object.keys( window.CSRF ).shift() ] );

				// Set loading class on table
				this.table.addClass( this.loadingClass );

				// Log searching status
				this.logger.log( "Searching." );

				Ajaxer.run( {
					"method" : "POST",
					"url" : Utils.formUrlWithParams( this.searchEndpoint, { resource : this.searchResource } ),
					"data" : data,
					"cache" : false,
					"contentType" : false,
					"processData" : false,
				}, this.onSearchResponse.bind( this ) );
			},

			onSearchResponse : function( err, resp ) {
				// Get data from response
				var response = resp[ 0 ];
				var data = resp[ 0 ] && resp[ 0 ].data ? resp[ 0 ].data : [];
				var totalCount = resp[ 0 ] && resp[ 0 ].meta && resp[ 0 ].meta.totalCount ? ~~resp[ 0 ].meta.totalCount : data.length;

				if (response && response.status && response.status === 401) {
					this.editChannel.trigger("auth:failed");
				}

				// Log search response
				this.logger.log('Searching response contains results.');

				// Update pagination totals
				this.paginatorTotals.update( this.pageOverride !== false ? this.pageOverride : 1, totalCount );

				// Remove all current models
				this.collection.each( function( model ) { model.trigger( "destroy" ); } );

				// Clean items container
				this.resetItemsContainer();

				// Reset collection with models from response
				this.collection.reset( this.wrapSearchResponses( data ) );

				// Update pagination
				this.paginator.update( this.pageOverride !== false ? this.pageOverride : 1, totalCount );

				// Update items
				this.resetItems();

				// Update table drop down selector
				this.onTableDropDownChanged();

				// Remove loading class from table
				this.table.removeClass( this.loadingClass );

				// Set searching value
				this.searching = false;

				// Reset page override value
				this.pageOverride = false;

				// Check if a re-search is needed
				if ( this.reSearch ) {
					// Reset flag value
					this.reSearch = false;

					this.handleChange();
				}
			},
			// End searching functions

			// Begin utility functions
			/**
			 * Activate basic filters, if they're not already active.
			 *
			 * NOTE: does not trigger search.
			 *
			 * @param {object} filters Basic filters you want to activate.
			 */
			activateBasicFilters : function ( filters ) {
				if ( !_.isObject( filters ) ) {
					this.logger.error( "Basic filters couldn't be applied! Please pass filters as a plain object." );
					return false;
				}

				// if `all` filter is present, just add active class to all filters and return
				if ( filters.all ) {
					this.basicFilters.addClass( this.activeClass );
					return;
				}

				_.each( filters, function( val, filterId ) {
					var target = this.basicFilters.filter( "[data-id='" + filterId + "']" );

					// if basic filter doesn't exist or is already active, continue
					if ( !target.length || target.hasClass( this.activeClass ) ) {
						return;
					}

					target.addClass( this.activeClass );
				}, this );
			},

			getActiveBasicFilterIds : function() {
				// Form default return array
				var ids = [];

				this.basicFilters.filter( "." + this.activeClass ).each( function( i, el ) {
					ids.push( el.getAttribute( "data-id" ) );
				}.bind( this ) );

				// Log filtered IDs
				this.logger.log( "Getting active basic filters: ", ids );

				return ids;
			},

			getDefaultSearchBody : function() {
				return {
					"searchText" : this.searchInput.length ? this.searchInput.val() : "",
					"searchTextType" : this.searchInputType.length ? this.searchInputType.val(): "",
					"page" : 1,
					"pageSize" : this.paginator.state.itemsPerPage,
					"advancedFilters" : {
						"disabled": "", // omits disabled content from search results
					},
					"basicFilters" : {},
					"overrideFilters" : {},
				};
			},

			getModelAttributesFromEl : function( el ) {
				return {
					_el : $( el ),
					id : el.id,
				};
			},

			resetAdvancedFilters : function() {
				// Log reset
				this.logger.log( "Resetting advanced filters" );

				var classList = [ this.activeClass, this.selectedClass ].join( " " );

				// Loop through selects, resetting each one in turn
				this.advancedFilterSelects.removeClass( this.validClass );
				this.advancedFilterSelects.each( function( i, el ) {
					var $el = $( el ),
					defaultSelected = $el.prop( "defaultSelected" );

					$el.val( defaultSelected === undefined ? -1 : defaultSelected );
				}.bind( this ) );

				// Reset editors
				this.advancedFiltersSearch.find( ".text.input" ).text( "" ).addClass( "default" );
				this.advancedFiltersSearch.find( ".menu .item" ).removeClass( classList );
				this.advancedFiltersSearch.find( "input[type='hidden']" ).removeClass( this.validClass );

				// Reset text inputs
				this.advancedFilters.find( "input[type='text']" ).each( function(i, el ) {
					el.value = "";
				}.bind( this ) );

				// Reset checkboxes
				this.advancedFilters.find( "input[type='checkbox']" ).each( function( i, el ) {
					el.checked = false;
				}.bind( this ) );
			},

			resetItemsContainer: function() {
				this.itemsContainer.find( this.options.itemSelector || ".item" ).remove();
			},

			resetItems : function() {
				// Reset items
				this.items = this.itemsContainer.find( this.options.itemSelector || ".item" );
			},

			resetState : function( includingFilters ) {
				// Log reset
				this.logger.log( "Resetting state" );

				// Reset advanced search variables
				this.advancedSearchContainer.removeClass( this.activeClass );
				this.advancedFiltersContainer.removeClass( this.activeClass );

				// NOTE: Normally it's not needed to reset filters, so put it behind an argument
				if ( includingFilters === true ) {
					// Reset basic filters
					this.basicFilters.removeClass( this.activeClass );

					// Reset advanced filters
					this.resetAdvancedFilters();
				}
			},

			scrollEl : function( position ) {
				// Scroll element to position (in pixels)
				this.scrollElement.animate( { scrollTop : ( position || 0 ) + "px" }, this.scrollSpeed );
			},

			setItems : function() {
				// Log item setting
				this.logger.log( "Setting items" );

				// Loop through items, forming attributes and adding to the collection
				this.items.each( function( i, el ) {
					this.collection.add( this.getModelAttributesFromEl( el ) );
				}.bind( this ) );
			},

			shouldUseModal : function() {
				return this.currentBreakpoint < this.modalBreakpoint;
			},

			wrapSearchResponses : function( responses ) {
				// Check if a wrap should occur
				if ( this.wrapResponses === false ) {
					return responses;
				}

				// Form default return array
				var temp = [];

				// Loop throuh each response and wrap it inside a "data" object property
				// NOTE: This helps prevent undefined errors in underscore templates when
				// attempting to access undefined properties
				responses.forEach( function( response ) {
					temp.push( { data : response } );
				} );

				return temp;
			},

			/**
			 * Convert a string to a boolean value.
			 * @param string
			 * @returns {boolean}
			 */
			stringToBoolean: function( string ) {
				return string.toLowerCase() === "true";
			},

			/**
			 * Get select input value
			 *
			 * @param {input} input Input element
			 *
			 * @return {string}
			 */
			getSelectValue: function( input ) {
				return input.find( ":selected" ).val();
			},

			/**
			 * Get checkbox input value
			 *
			 * @param {input} input   Input element
			 *
			 * @return {string}
			 */
			getCheckboxValue: function( input ) {
				// Extract values from multiple check boxes elements
				if ( this.enableHierarchicalSelect ) {
					return this.getMultipleCheckboxValue( input );
				}

				// Extract value from a single check box element
				return input[ 0 ].checked ? 1 : 0;
			},

			/**
			 * Get multiple checkboxes values, the result will be a comma
			 * separate list of values
			 *
			 * @param {input} input   Input element
			 *
			 * @return {string}
			 */
			getMultipleCheckboxValue: function( inputs ) {
				var values = [];

				// Extract selected check boxes values
				inputs.each( function( index, element ) {
					if ( element.checked ) {
						values.push( element.value );
					}
				} );

				return values.join();
			},

			/**
			 * Get radio input value
			 *
			 * @param {input} input   Radio input element
			 *
			 * @return {string}
			 */
			getRadioValue: function( input ) {
				return input.parent().find( ":checked" ).val();
			},

			/**
			 * Get date picker widget value
			 *
			 * @param      {input}  input   Input element
			 * @return     {string}         Date value at ISO format.
			 */
			getDateValue: function( input ) {
				return input.val() ?  new Date( input.val() ).toISOString() : "" ;
			},

			/**
			 * Get default input value
			 *
			 * @param {input} input   Input element value
			 *
			 * @return {string}
			 */
			getDefaultInputValue: function( input ) {
				return input.val();
			}
			// End utility functions
		} );
	}
);
