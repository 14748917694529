require(
    // Define module dependencies
    [
        "@datadog/browser-rum",
    ],

    function( datadog ) {
        "use strict";

        const applicationId = window.DATADOG_RUM_APP_ID;
        const clientToken = window.DATADOG_RUM_CLIENT_TOKEN;

        if (datadog && datadog.datadogRum && applicationId && clientToken) {
            datadog.datadogRum.init({
                applicationId,
                clientToken,
                site: 'datadoghq.com',
                service: 'edit-ui',
                env: window.ENV,
                // Specify a version number to identify the deployed version of your application in Datadog
                // version: '1.0.0',
                sessionSampleRate: 100,
                sessionReplaySampleRate: 20,
                trackUserInteractions: true,
                trackResources: true,
                trackLongTasks: true,
                defaultPrivacyLevel: 'mask-user-input',
            });

            // datadogRum.startSessionReplayRecording();
        }
    }
);
