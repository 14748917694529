/**
 * Logger defines a shared logger for use within various modules
 *
 * Usage:
 *     // This string will be appended to console output - "NAME HERE: Foo"
 *     this.logger = new Logger( "Name Here" );
 *
 *     this.logger.error( "Foo" ); // Outputs with the console.error
 *     this.logger.info( "Foo" ); // Outputs with the console.info
 *     this.logger.log( "Foo" ); // Outputs with the console.log
 *     this.logger.warn( "Foo" ); // Outputs with the console.warn
 *
 * This module allows for string substitution as well
 *     this.logger = new Logger( "Name Here" );
 *
 *    // Outputs "Output 1, 2, 3, 4"
 *     this.logger.log( "Output %d, %d, %d, %d", 1, 2, 3, 4 )
 */
define(
  'logger',

  // Define require.js dependencies
  ['raven', 'underscore', 'utils'],

  function logger(Raven, _, Utils) {
    var Logger = function Logger(name, debugOnly, ravenOptions) {
      // Set up logger name
      this.name = typeof name === 'string' ? name.toUpperCase() : 'LOGGER';

      // Set up logger prefix
      this.prefix = this.name + ': ';

      // debugOnly indicates if logs should only be used when a "debug" query param is present
      this.debugOnly = debugOnly === undefined ? true : !!debugOnly;

      // set raven options
      this.ravenOptions = ravenOptions || {};

      // The context the log message is in, used to pass along to Sentry
      // containing the user's ID or the namespace of the logger
      this.context = Utils.getValue('CURRENT_USER.id', window, this.name);

      // Initialize raven client
      this.setup(window.SENTRY_DSN);
    };

    Logger.prototype = {
      globalRavenOptions: window.SENTRY_OPTIONS || {},
      defaultRavenOptions: {
        tags: {
          site: Utils.getValue('CURRENT_SITE.brand.name', window, ''),
          country: Utils.getValue('CURRENT_LOCALE.country', window, ''),
          language: Utils.getValue('CURRENT_LOCALE.language', window, ''),
        },
        ignoreErrors: [
          // Random plugins/extensions
          'top.GLOBALS',
          // Facebook borked
          'fb_xd_fragment',
          // Facebook timeout
          'Load timeout for modules: facebook',
        ],
        ignoreUrls: [
          // Facebook flakiness
          /graph\.facebook\.com/i,
          // Facebook blocked
          /connect\.facebook\.net\/en_US\/all\.js/i,
          // Chrome extensions
          /extensions\//i,
          /^chrome:\/\//i,
        ],
        maxBreadcrumbs: 1,
      },

      /**
       * Error is used to call console.error with formatted arguments.
       * NOTE: The first argument used for this function MUST be a string.
       */
      error: function error() {
        // Call console.error, applying formatted arguments
        this.call('error', this.formatArgs(arguments));
      },

      /**
       * Info is used to call console.info with formatted arguments.
       * NOTE: The first argument used for this function MUST be a string.
       */
      info: function info() {
        // Call console.info, applying formatted arguments
        this.call('info', this.formatArgs(arguments));
      },

      /**
       * Log is used to call console.log with formatted arguments.
       * NOTE: The first argument used for this function MUST be a string.
       */
      log: function log() {
        // Check if logs should only be logged in debug mode, and if so, check for debug mode
        if (this.debugOnly !== false && !this.isDebugMode()) {
          // Record trail of events before exiting
          this.capture('log', this.formatArgs(arguments));
          return;
        }

        // Call console.log, applying formatted arguments
        this.call('log', this.formatArgs(arguments));
      },

      /**
       * Warn is used to call console.warn with formatted arguments.
       * NOTE: The first argument used for this function MUST be a string.
       */
      warn: function warn() {
        // Call console.warn, applying formatted arguments
        this.call('warn', this.formatArgs(arguments));
      },

      /**
       * Call actually calls a method on console, applying formatted arguments.
       *
       * @param {string}  method The console method to call
       * @param {mixed[]} args   An array of formatted arguments to apply to the console method
       */
      call: function call(method, args) {
        // Capture arguments in sentry
        this.capture(method, args);
        // Call console method with formatted arguments
        // eslint-disable-next-line no-console
        console[method].apply(console, args);
      },

      /**
       * Initializes the raven client for sentry logging.
       *
       * @param {string} dsn Public dsn
       */
      setup: function setup(dsn) {
        // If a public dsn isn't set or if Raven is already setup, return
        if (typeof dsn !== 'string' || Raven.isSetup()) {
          return;
        }
        // merge all options
        this.ravenOptions = _.extend(
          this.defaultRavenOptions,
          this.globalRavenOptions,
          this.ravenOptions
        );
        // initialize client
        try {
          Raven.config(dsn, this.ravenOptions)
            .install()
            .setUserContext({
              id: this.context,
            });
        } catch (err) {
          this.warn('Raven setup failed.', err);
        }
      },

      /**
       * Captures breadcrumbs, exceptions and messages to sentry.
       *
       * @param {string}  level The console level ( log, warn, info, error )
       * @param {mixed[]} args  An array of formatted arguments to apply to the call
       */
      capture: function capture(level, args) {
        var options;

        // If raven is not setup or arguments isn't an array, exit
        if (!Raven.isSetup() || !_.isArray(args)) {
          return;
        }
        // set default options
        options = {
          level: level === 'warn' ? 'warning' : level,
        };
        // Capture exception or breadcrumb based on the console call level
        switch (level) {
          case 'error':
            // if the second argument is an object, pass it as extra
            if (_.isObject(args[1])) {
              options.extra = args[1];
            }

            // capture exception
            Raven.captureException(args[0], options);
            break;
          default:
            // add message to options
            options.message = args[0];
            // if the second argument is an object, pass it as data
            if (_.isObject(args[1])) {
              options.data = args[1];
            }
            // capture breadcrumb
            Raven.captureBreadcrumb(options);
            break;
        }
      },

      /**
       * FormatArgs takes a function's arguments, appends this module's prefix
       * to the first argument (must be a string) and then replaces it in the arguments array.
       *
       * @param  {array} args     A function's arguments
       *
       * @return {array}          An array of formatted arguments
       */
      formatArgs: function formatArgs(args) {
        // Append prefix to first argument
        var str = this.prefix + [].slice.call(args, 0, 1).shift();

        // Replace first item in args array with prefixed string
        args = [str].concat([].slice.call(args, 1));

        return args;
      },

      /**
       * isDebugMode checks if the page is in "debug" mode, indicated by a "debug" query param.
       * Used to determine if logs should be logged or not.
       *
       * @param  {string}  param      The query param to check for
       *
       * @return {boolean}            Whether logs should be logged or not
       */
      isDebugMode: function isDebugMode(param) {
        var regex;
        var matches;
        param = param || 'debug';

        // Allows filtering by specific prefix names
        // https://regex101.com/r/Cv34SI/4
        regex = new RegExp('[?&]' + param + '([=](.*?(?=(?:&|$))))?(?=(?:&|$))');
        matches = regex.exec(this.getWindowLocationSearch());

        if (_.isArray(matches) && !_.isEmpty(matches[2])) {
          matches = matches[2].toUpperCase().split(',');

          return matches.indexOf(this.name) >= 0;
        }

        return matches !== null;
      },

      /**
       * Returns window.location.search, makes stubbing easier.
       *
       * @return {string} window.location.search
       */
      getWindowLocationSearch: function getWindowLocationSearch() {
        return window.location.search;
      },
    };

    return Logger;
  }
);
