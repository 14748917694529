define(
	// Define module dependencies
	[
		"backbone",
		"utils",
		"mousetrap",
		"shared/SaveStatusBar",
		"underscore",
		"backbone.radio",
	],

	function( Backbone, Utils, Mousetrap, SaveStatusBar, _ ) {
		"use strict";

		/**
		 * Modal defines a set of functionality that will be used for modal windows
		 *
		 * @class Modal
		 * @extends Backbone.View
		 */
		return Backbone.View.extend( {
			el : ".modal-container",

			events : {
				"click .close" : "onCloseClicked",

				"click .save" : "save"
			},

			/**
			 * An object containing keys, representing a button name and
			 * values, representing any button properties.
			 *
			 * `classname` here refers to the class that will be added to the
			 * modal header, determining which close button will show,
			 * abstracted here to keep class definitions in one place.
			 *
			 * @type {Object}
			 */
			closeButtons: {
				close: {
					classname: "closable",
				},
				delete: {
					classname: "deletable",
				}
			},

			initialize : function() {
				this.setVariables();

				this.setListeners();

				// show/hide close icon button based on options
				if ( !this.hideCloseButton ) {
					this.showButton( "close" );
				}
			},

			// Begin setter functions
			setVariables : function() {
				// If we have multiple lists, we'll need to namespace our channels
				this.namespace = this.options.namespace || "";

				// Class referencing the modal inner
				// Used to figure out if "background" or "foreground" is being clicked
				this.innerClass = this.options.innerClass || "modal-container-inner";

				// Modal Name.  Used to reference the modal when closed or shown
				this.modalName =  Utils.namespaceString( this.options.modalName || "modal", this.namespace );

				// Class to use when showing modal
				this.shownClass = this.options.shownClass || "shown";

				// A Jquery obj. that represents the modal header that contains the close
				// and save buttons
				this.$header = this.$( ".modal-header" );

				// Close button element (x icon in header)
				this.$closeButton = this.$( ".modal-header .close" );

				// Whether to hide close button. Defaults to false
				this.hideCloseButton = this.options.hideCloseButton === true;

				// The selector used to target
				this.floatLabelSelector = this.options.floatLabelSelector || ".js-float-label";

				// ESC button should close the modal. Default to true.
				this.escToClose = this.options.escToClose !== false;

				// status bar
				this.saveStatusBar = new SaveStatusBar();

				// Whether SCSS is animating hiding the element or not
				this.elementAnimates = this.options.elementAnimates !== undefined ?
					!!this.options.elementAnimates : true;

				// Animation time (in milliseconds)
				this.animationTime = this.options.animationTime !== undefined ?
					parseInt( this.options.animationTime, 10 ) : 300;

				// Set the modal's channel. If this.namespace is set on an instantiated modal,
				// the channel name will be properly namespaced.  This is useful for pages with multiple modals.
				this.channel = Backbone.Radio.channel( Utils.namespaceString( "modal", this.namespace ) );

				// DOM lookups
				this.$html = $( "html" );
				this.$body = $( "body" );

				// Ajax Messages
				this.ajaxMessages = Utils.jsonParse( this.$el.find( "[data-ajax-messages]" ).attr( "data-ajax-messages" ) || {} );
			},

			setListeners : function() {},
			// End setter functions

			// Begin public API functions
			/**
			 * Display the modal
			 *
			 * @param  {function} callback Function to execute after showing the modal
			 * @return {Modal}             Reference to `this` to allow for chaning
			 */
			show : function( callback ) {
				// Only show if hidden
				if ( !this.isShown() ) {
					this.$el.addClass( this.shownClass );

					// Announce show to Backbone.Radio
					Utils.sendChannelMessage( this.channel, "show" );

					// Call callback based in whether the element needs time to animate in
					this.setCallbackTimeout( callback );

					// Lock background
					this.toggleLock( true );

					// listen for esc clicks to close the modal
					// NOTE: Has to be bound in the `show()` method to prevent Mousetrap
					// multiple `esc` click events every time a different modal
					// is instantiated.
					if ( this.escToClose ) {
						Mousetrap.bindGlobal( "esc", this.hide.bind( this ) );
					}
				}

				return this;
			},

			/**
			 * Hide the modal
			 *
			 * @param  {function} [callback]  Function to execute after hiding the modal
			 * @return {Modal} Reference to `this` to allow for chaning
			 */
			hide : function( callback ) {
				// If it's not visible, just return
				if ( !this.isShown() ) {
					return this;
				}

				// remove the shown class from the modal
				this.$el.removeClass( this.shownClass );

				// Announce hide to Backbone.Radio
				Utils.sendChannelMessage( this.channel, "hide" );

				// Call callback based in whether the element needs time to animate out
				this.setCallbackTimeout( callback );

				// Unlock background
				this.toggleLock( false );

				// unbind esc clicks
				Mousetrap.unbind( "esc" );

				return this;
			},

			save : function( callback ) {
				// Announce save to Backbone.Radio
				Utils.sendChannelMessage( this.channel, "save" );

				// Call callback based in whether the element needs time to animate out
				this.setCallbackTimeout( callback );
			},
			// End public API functions

			// Begin event listener functions
			onCloseClicked : function() {
				// Announce close clicked to Backbone.Radio
				Utils.sendChannelMessage( this.channel, "close:clicked" );

				// Hide modal
				this.hide();

				// Clear product status
				const contentProductStatus = $(this.$el[0]).find('.content-product-status');
				const modalContent = $(this.$el[0]).find('.modal-content');
				contentProductStatus.removeClass( "icon-no-preview" ).addClass( "icon-preview" );
				modalContent.removeClass( "status-1" ).addClass( "status-3" );
			},
			// End event listener functions

			// Begin utility functions
			isShown : function() {
				return this.$el.hasClass( this.shownClass );
			},

			setCallbackTimeout : function( callback ) {
				setTimeout( Utils.errorCheckCallback( callback ).bind( this ), this.elementAnimates ? this.animationTime : 0 );
			},

			/**
			 * Toggles the valid class and triggers the input event on any inputs contained
			 * in the modal
			 */
			setInputState : function () {
				this.$( this.floatLabelSelector ).trigger( "input" );
			},

			/**
			 * Public method toggleLock
			 *
			 * Prevents body scroll when modal is open
			 * @param {boolean} bodyLocked whether the body should be locking or not
			 */
			toggleLock: function( bodyLocked ) {
				// all viewports
				this.$html.toggleClass( "no-scroll", bodyLocked );

				// mobile
				if ( Modernizr.touchevents ) {
					if ( bodyLocked ) {
						this.bodyOffset = this.$body.scrollTop();
					} else {
						window.scrollTo( 0, this.bodyOffset );
					}
				}
			},

			/**
			 * Get ajax message associated with modal.
			 *
			 * @param {string} key  Message key/slug
			 * @param {string} defaultValue  Default message if none found
			 * @return {string} Ajax message
			 */
			getAjaxMessage : function( key, defaultValue ) {
				// Return message if present, otherwise return fallback
				return this.ajaxMessages[ key ] || ( defaultValue !== undefined ? defaultValue : "" );
			},

			/**
			 * Removes all close button-related classes from the modal header
			 */
			resetCloseButtons: function() {
				_.each( this.closeButtons, function( button ) {
					this.$header.removeClass( button.classname );
				}.bind( this ) );
			},

			/**
			 * Adds the classname that corresponds to the given button name
			 * to the crop modal's header
			 */
			showButton: function( name ) {
				// Check the name provided is a thing
				if ( !_.has( this.closeButtons, name )  ) {
					return;
				}

				// Remove all classes from the header
				this.resetCloseButtons();

				// Based on the name, add the appropriate class
				this.$header.addClass( Utils.getValue( name + ".classname", this.closeButtons ) );
			},
			// End utility functions
		} );
	}
);
