define(
	// Define module dependencies
	[
		"jquery",
		"backbone",
		"underscore",
		"utils"
	],
	function( $, Backbone, _, Utils ) {
		"use strict";

		/**
		 * NOTE: This model expects it's attributes to be wrapped in a `data` object
		 * This is to allow for more graceful error checking when using the model in an
		 * underscore template
		 * Without the wrap, undefined properties will always throw an error, whereas with the
		 * wrap, underscore is more forgiving with missing object property accesses. I.e.
		 *
		 * <%= imNotDefined %> will throw an error without more complicated _.isUndefined() checks
		 * <%= data.imNotDefined %> is more forgiving and allows for better error checking
		 *
		 * For more information, see
		 * https://github.com/jashkenas/underscore/issues/237
		 * http://www.position-absolute.com/articles/handling-variable-is-not-defined-with-underscore-template-engine/
		 *
		 * Extending models can not use this method by overriding the getAttributeFromData method
		 *
		 * @class GenericModelView
		 * @extends Backbone.Model
		 */
		var Model = Backbone.Model.extend( {
			defaults : {
				"_el" : false,
				"order" : 0,
			},

			mediaRoles : {
				"slide" : 1,
				"social" : 2,
				"lede" : 3,
				"body_media" : 4,
				"marquee" : 5,
				"sponsored_marquee" : 6,
				"preview" : 7,
				"cover_story_marquee" : 20,
			},

			/**
			 * Used to return a nested attribute from within the "data" attribute
			 *
			 * NOTE: This ties in to the explanation in the docblock above
			 *
			 * @param  {string} attribute  Name of the attribute
			 * @return {mixed}             Value of the attribute, or `undefined` if it's not found
			 */
			getAttributeFromData : function( attribute ) {
				return this.get( "data" ) && this.get( "data" ).hasOwnProperty( attribute ) ? this.get( "data" )[ attribute ] : undefined;
			},

			/**
			 * Get a single attribute from the model
			 *
			 * NOTE: checks to see if the attribute is nested within the data
			 * attribute first, if not, it will then just uses the native
			 * `Backbone.Model.get()`
			 *
			 * @uses this.getAttributeFromData
			 * @param  {string} attribute  Name of the attribute
			 * @return {mixed}             Value of the attribute, or `undefined` if it's not found
			 */
			getAttribute : function( attribute ) {
				return this.getAttributeFromData( attribute ) || this.get( attribute );
			},

			/**
			 * Returns all of the models' attributes
			 *
			 * Checks to see if a data attribute exists, and if so returns
			 * all of the attributes nested within the data attribute. Otherwise
			 * will just return whatever is in the model attributes property
			 * (empty `Object {}` if there's no attributes).
			 *
			 * @return {Object} All of the models attributes
			 */
			getAttributes : function() {
				return this.get( "data" ) || this.attributes;
			},

			/**
			 * Set an attribute on the model
			 *
			 * NOTE: checks to see if the model's attributes are nested within the data
			 * attribute first, and if so, it will set the attribute in `data`. If not,
			 * it will then just uses the native `Backbone.Model.set()`
			 *
			 * @param  {string} attribute  Name of the attribute
			 * @param  {mixed}  value      Value of the attribute
			 */
			setAttribute : function( attribute, value ) {
				var data = this.get( "data" );

				if ( data ) {
					data[ attribute ] = value;
					this.set( "data", data );
				} else {
					this.set( attribute, value );
				}
			},

			/**
			 * Used to return a string of classes to be applied to the model's view
			 *
			 * NOTE: Useful for adding classes like "status" and "display type" to view elements
			 */
			getElementClasses : function() {
				return [ this.getDisplayTypeSlug(), this.getStatusClass() ].join( " " );
			},

			getDisplayTypeSlug : function() {
				// Get display type from attributes
				var displayType = this.getAttribute( "display_type" );

				return displayType && displayType.title ? displayType.title.toLowerCase().replace( /\s+/g, "-" ) : "";
			},

			getStatusClass : function() {
				// Get status from attributes
				var status = this.getAttribute( "status" );

				return status && status.class ? status.class : "";
			},

			// Get image from metadata
			// NOTE: will be changing to `media` soon
			getMedia : function( role, type ) {
				type = type || "image";

				var roleId = Utils.getValue( role, this.mediaRoles ),
				media = _.findWhere( this.getAttribute( "media" ), { "role" : roleId, "media_type" : type } );

				return media || false;
			},

			getImageUrl : function( options ) {
				// Get image
				var params = _.clone( options || {} ),
				image = this.getMedia( params.role || "social" );

				if ( !image ) {
					return false;
				}

				// Get the image path, filename and crop string
				var path = this.trimSlash( Utils.getValue( "media_object.path", image, "" ) ),
				filename = this.rTrimSlash( Utils.getValue( "media_object.filename", image, "" ) ),
				crop = params.crop || "2x1",
				cropString = Utils.getValue( "metadata.crops." + crop, image, "" ),
				imageDomain = Utils.getValue( "metadata.domain", image, "" ) || Utils.getValue( "metadata.legacy_domain", image, "" ),
				url = [],
				hipsHost = this.getHipsHost( imageDomain );

				// cleanup hips parameters
				delete params[ "role" ];

				// if this crop is a legacy crop, attach hips domain to the front
				// and append params to the back.
				// we also must strip scheme and take out crop parameters.
				if ( cropString && cropString.indexOf( "http" ) !== -1 ) {
					// strip out crop in param. we don't need it.
					delete params[ "crop" ];
					url.push( this.getHipsHostDomain() );
					url.push( this.stripScheme( cropString ) );
					return Utils.formUrlWithParams( url.join( "/" ), params );
				}

				// ensure cropstring only gets populated if it exists.
				delete params[ "crop" ];
				if ( cropString ) {
					params[ "crop" ] = cropString;
				}

				// ensure we only resize images that are bigger than the resize value
				params = this.checkResizeParam( image, params );

				// Form image URL
				if ( hipsHost ) {
					url.push(hipsHost);
				}

				if ( path ) {
					url.push(path);
				}

				if ( filename ) {
					url.push(filename);
				}

				return Utils.formUrlWithParams( url.join( "/" ), params );
			},

			/**
			 * Remove the resize from the parameters if the image is smaller than
			 * the resize value.
			 *
			 * @param  {Object} image  Image object
			 * @param  {Object} params Parameters
			 * @return {Object}        Parameters
			 */
			checkResizeParam: function( image, params ) {
				var resize = Utils.getValue( "resize", params );

				if ( resize ) {
					// resize param format is width:height
					var sizes = resize.split( ":" ),
					width = Utils.getValue( "media_object.width", image, 0 ),
					height = Utils.getValue( "media_object.height", image, 0 );

					if ( ( width !== "*" && sizes[ 0 ] > width ) || ( height !== "*" && sizes[ 1 ] > height )) {
						delete params[ "resize" ];
					}
				}

				return params;
			},

			getHipsHostDomain: function() {
				var a = document.createElement( "a" );
				a.href = window.PUBLIC_IMAGE_HOSTNAME;

				return a.origin;
			},

			/**
			 * Remove the slash at the beginning and end of a string
			 *
			 * @param  {string} str  String
			 * @return {string}      String or empty
			 */
			trimSlash: function( str ) {
				return str && str.length ? str.replace( /^\/|\/$/g, "" ) : "";
			},

			/**
			 * Remove the slash at the end of a string
			 *
			 * @param  {string} str  String
			 * @return {string}      String or empty
			 */
			rTrimSlash: function( str ) {
				return str && str.length ? str.replace( /\/$/, "" ) : "";
			},

			// strips scheme from a domain.
			stripScheme: function( domain ) {
				return domain.replace( /https?:\/\//, "" );
			},

			getHipsHost: function(imageDomain){
				// if imageDomain does not exist, or is blank, we return public image hostname.
				if ( !imageDomain ) {
					return this.rTrimSlash( window.PUBLIC_IMAGE_HOSTNAME );
				}

				// if imageDomain  does not have a host, we assume it's a hips-prefix.
				// we return host/imageDomain with the default hips-prefix stripped out.
				var hipsHost = this.getHipsHostDomain();
				if ( imageDomain.indexOf( "http" ) === -1 ){
					return hipsHost + "/" + this.rTrimSlash( imageDomain );
				}

				// if imageDomain is a full path, meaning it's a link to an aws bucket.
				// we strip out scheme and append only the hostname to hips domain.
				var i = document.createElement( "a" );
				i.href = imageDomain;
				return hipsHost + "/" + this.rTrimSlash( i.hostname );
			},

			setImageUrl : function( params ) {
				// if image src exists already, don't do anything, just return
				if ( this.getAttribute( "image" ) ) {
					return this;
				}

				var imageUrl = this.getImageUrl( params );

				// if building the image url failed, return early,
				// dont set the attribute
				if ( !imageUrl ) {
					return this;
				}

				this.setAttribute( "image", this.getImageUrl( params ) );

				return this;
			},
		} );

		/**
		 * GenericModelView is a generic model/view combination used for various pages
		 *
		 * NOTE: This model is also used as the basis for other models throughout the system
		 *       To extend it, require this module and access via:
		 *       `GenericModelView.prototype.ModelTemplate`
		 *
		 * @class GenericModelView
		 * @extends Backbone.View
		 */
		return Backbone.View.extend( {
			className : "item",

			tagName : "article",

			// NOTE: Allows importing classes to use the model via `GenericModelView.prototype.ModelTemplate`
			ModelTemplate : Model,

			events : {
				"click" : "onClicked",
				"click .icon-trash" : "onDeleteClicked",
			},

			// Begin top-level functions
			initialize : function() {
				// Set template selector from options with fallback
				this.templateSelector = this.options.templateSelector || "#generic-item-template";

				// Set template
				this.template = _.template( $( this.templateSelector ).length ? $( this.templateSelector ).html() : "" );

				// Check if _el already exists and set up view if so
				if ( this.model.get( "_el" ) !== false ) {
					this.$el = this.model.get( "_el" );

					// Call render function, instructing not to set $el
					this.render( false );

					// NOTE: Exisiting views need to re-delegate events after setting up the $el
					this.delegateEvents( this.events );
				}
			},

			render : function( setEl ) {
				// Render template into $el if requested
				if ( setEl !== false ) {
					this.$el.html( this.template( this.model.toJSON() || {} ) );

					this.$el.attr( "id", this.model.get( "id" ) );

					// Add classes from model to element
					// NOTE: Allows models to specify classes (such as status, display type, etc)
					this.$el.addClass( this.model.getElementClasses() );

					this.model.set( "_el", this.$el );
				}

				this.setVariables();

				this.setListeners();

				return this;
			},
			// End top-level functions

			// Begin setter functions
			setVariables : function() {
				// Set parent from options
				this.parent = this.options.parent || null;
			},

			setListeners : function() {
				// Listen to various model events
				this.listenTo( this.model, "change", this.modelChanged );
				this.listenTo( this.model, "destroy", this.remove );
			},
			// End setter functions

			// Begin model event functions
			modelChanged : function() {},
			// End model event functions

			// Begin event listener functions
			onClicked : function( e ) {
				// Trigger an event the parent can listen for and act upon
				this.trigger( "viewClicked", e );
			},

			onDeleteClicked : function( e ) {
				// Trigger an event the parent can listen for and act upon
				this.trigger( "deletedClicked", e, this.model );
			},
			// End event listener functions
		} );
	}
);
