define(
	// Define module dependencies
	[
		"backbone"
	],

	function ( Backbone ) {
		"use strict";

		/**
		 * FixedElement handles scroll events and the addition of a class to the body element when certain scroll conditions are met
		 *
		 * @class FixedElement
		 * @extends Backbone.View
		 */
		var FixedElement = Backbone.View.extend( {
			el : "body", // Since this can be used on any element on the page, bind to the body

			initialize : function() {
				this.setVariables();

				// Check the initial state of the fixed items
				this.checkInitialState();

				this.setListeners();
			},

			// Begin getter and setter functions
			setVariables : function() {
				// Window vars
				this.window = $( window );

				// Instantiate an object that will contain the elements to be fixed
				this.fixedElement = {};

				// The element that is to be fixed
				this.element = this.options.element || null;

				// The position of the element from the top of the window
				this.offset = this.getOffset();

				// Set the class name that will be set on the body element
				this.fixedClass = this.options.fixedClass || "fixed-header";

				// Set a default point, in pixels, at which to fix the element
				this.fixPoint = 0;

				// Set a default point, in pixels, at which to unfix the element
				this.unfixPoint = 999999999;

				// If we have a container that is also fixed above this one, we'll want to take in consider this other element's height
				if ( this.options.fixedContainerAbove ) {
					this.fixPoint = this.getAboveContainerHeight();
				}

				// If passed in a container at which to unfix, get a height that measures to its bottom edge
				if ( this.options.unfixContainer ) {
					this.unfixPoint = this.getUnfixContainerHeight();
				}
			},

			setListeners : function() {
				// Set up to listen for scroll events
				this.window.on( "scroll mousewheel", this.onWindowScroll.bind( this ) )
					.on( "resize", this.onWindowResize.bind( this ) );
			},

			getOffset : function() {
				return this.element && this.element.length ? this.element.offset().top : 0;
			},
			// End getter and setter functions

			// Begin UI event functions
			onWindowScroll : function() {
				// Get our current distance from the top of the window
				var scrollTop = this.window.scrollTop();

				// If we have scrolled past the the top of the element, fix the element to the top of the document
				// We also want to take into consideration the case where we have another fixed element "on top" of this one
				this.$el.toggleClass( this.fixedClass, this.shouldFix( scrollTop ) );

				// Let's reset the fixed class, if we're at the top of the document window
				if ( scrollTop === 0 ) {
					this.resetFixedClass();
				}
			},

			onWindowResize : function() {
				// Recalculate the points at which we fix/unfix the element, as these may have changed
				this.fixPoint = this.getAboveContainerHeight();
				this.unfixPoint = this.getUnfixContainerHeight();

				// Reset the offset of the fixed element, as this may have changed
				this.offset = this.getOffset();

				// Remove the fixedClass from the body element
				this.resetFixedClass();

				// See where we are on the page and add the fixedClass if needed
				this.checkInitialState();
			},
			// End UI functions

			// Begin util functions
			checkInitialState : function() {
				// Get our current distance from the top of the window
				var scrollTop = this.window.scrollTop();

				if ( this.shouldFix( scrollTop ) ) {
					this.$el.addClass( this.fixedClass );
				}
			},

			shouldFix : function( scrollTop ) {
				var distance = this.offset - scrollTop,
					unfixOffset = scrollTop + this.fixPoint;

				return distance < this.fixPoint && unfixOffset < this.unfixPoint;
			},

			resetFixedClass : function() {
				this.$el.removeClass( this.fixedClass );
			},

			getAboveContainerHeight : function() {
				// Return the height of the container above so we can add it to the fixpoint
				if ( this.options.fixedContainerAbove ) {
					return ~~this.options.fixedContainerAbove.height();
				}

				// If there was not a fixed container above that was passed in as an option, return 0
				return 0;
			},

			getUnfixContainerHeight : function() {
				// Return the height of the unfix container, plus its offset, so we can set an unfix point
				if ( this.options.unfixContainer ) {
					return ~~this.options.unfixContainer.offset().top + this.options.unfixContainer.height();
				}

				// If there was not an unfix container passed in as an option, return a massive number
				return 999999999;
			}
			// End util functions
		} );

		return FixedElement;
	}
);
