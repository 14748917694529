define(

	// Define module dependencies
	[
		"backbone",
		"underscore",
		"logger",
		"utils",
		"shared/IdGenerator",
		"backbone.radio",
	],

	function( Backbone, _, Logger, Utils, IdGenerator ) {
		"use strict";

		/**
		 * @type PageView
		 * @extends Backbone.View
		 */
		var PageView = Backbone.View.extend( {
			className : "page",

			tagName : "li",

			events : {
				"click" : "onClicked",
			},

			initialize: function() {
				this.template = _.template( $( "#pagination-item-template" ).length ? $( "#pagination-item-template" ).html() : "" );
			},

			render : function() {
				this.$el.html( this.template( this.model.toJSON() || {} ) );

				this.setListeners();

				// Handle custom classes
				if ( this.model.get( "classes" ).length ) {
					this.$el.addClass( this.model.get( "classes" ) );
				}

				return this;
			},

			// Begin setter functions
			setListeners : function() {
				// Listen to various model events
				this.listenTo( this.model, "destroy", this.remove );
			},
			// End setter functions

			// Begin event listener functions
			onClicked : function( e ) {
				// Prevent event actions
				Utils.preventEventActions( e );

				// Trigger page clicked event
				this.trigger( "pageClicked" );
			},
			// End event listener functions
		} ),

		/**
		 * @class Page
		 * @extends Backbone.Model
		 */
		Page = Backbone.Model.extend( {
			defaults : {
				classes : "",
				isLink : true,
				page : "",
				url : "",
			}
		} );

		/**
		 * Paginator is a shared module that can be used for handling pagination of listing pages
		 *
		 * Pagination in the platform consists of a couple of steps and terms. Namely, a page group
		 * is calculated, then ellipses are added when needed, and finally arrow states are applied.
		 *
		 * A page group consists of a set of pages. The group size is determined by a variable set in the module
		 * (groupSize) and should ALWAYS BE ODD. A group starts with the current page and then each "side" of the group
		 * is filled as well as it can be.
		 *
		 * For instance, if the current page were 4, the total pages available were 10, and the group size
		 * was 5, the current page would be used and the middle of the group and then each side would be
		 * filled with two additional pages, resulting in a group of: 2, 3, 4, 5, 6
		 *
		 * Ellipses are added as needed. The logic contained within this module applies to both sides of the group. On the left,
		 * if the lowest page in the group is 1, no ellipses are applied. Similarly on the right, if the highest page
		 * in the group is the "top" page (i.e. the total number of pages), no ellipses are applied.
		 *
		 * If the lowest page is higher than three, two pages are added: 1 and a set of ellipses. If the lowest page is three,
		 * two pages are added, but instead of an ellipses, a 2 is added (since the ellipses would just be replacing the 2 anyways).
		 * Similar logic is applied to the right side.
		 *
		 * For arrow states, the left arrow is considered "active" if the current page is not 1 (i.e. current page > 1). The right
		 * arrow is considered "acive" if the current page is not the "top" page (i.e. the total number of pages). For arrows
		 * that are not "active", a link href of # is applied, and this module contains logic to stop event actions for that
		 * href.
		 *
		 * @class Paginator
		 * @extends Backbone.View
		 */
		return Backbone.View.extend( {
			events : {
				// Arrow events
				"click .arrow" : "onArrowClicked",
			},

			initialize : function() {
				// Set variables
				this.setVariables();

				// Set listeners
				this.setListeners();

				// Map data attributes
				this.mapDataAttributes();

				// Use shared function to kick off calculation/render
				this._render();
			},

			update : function( current, items, itemsPerPage ) {
				// Log update
				this.logger.log( "Updating with new state." );

				// Set state
				this.state = this.getFreshState();

				// Set state variables from arguments
				this.state.current = current;
				this.state.items = items;
				this.state.itemsPerPage = itemsPerPage || parseInt( this.$el.attr( "data-items-per-page" ), 10 );

				// Remove all current models
				this.collection.each( function( model ) { model.trigger( "destroy" ); } );

				// Use shared function to kick off calculation/render
				this._render();
			},

			_render : function() {
				// Refresh the plugin to configure state
				this.refresh();

				// Check if there is more than one page
				if ( this.state.total <= 1 ) {
					this.$el.removeClass( this.activeClass );

					return false;
				}

				// Calculate state
				this.calculateState();

				// Reset collection
				this.collection.reset( this.pagesToModels() );
			},

			// Begin setter functions
			setVariables : function() {
				// Set logger
				this.logger = new Logger( "Paginator" );

				// Set a unique ID for this paginator view
				this.id = IdGenerator.generate();

				// Set up Backbone channel
				// NOTE: the channels need to be unique in this case otherwise
				// every subscriber to this channel will execute their listeners, but
				// paginator instantiations are meant to be specific to single lists.
				this.channel = Backbone.Radio.channel( Utils.namespaceString( "paginator", this.id ) );

				// Set default state
				// NOTE: State represents all things relating to the current page calculations
				//       for a given current page/total pages combination
				this.state = this.getFreshState();

				// Set group size
				this.groupSize = this.options.groupSize !== undefined ? ~~this.options.groupSize : 3;

				// Set text to be used when adding ellipses to page state
				this.ellipsesText = this.options.ellipsesText || "...";

				// Set class to be used when indicating an element is "active"
				// NOTE: Applies to various elements in the pagination wrapper
				this.activeClass = this.options.activeClass || "active";

				// Set class to be applied to the current page
				this.currentClass = this.options.currentClass || "current";

				// Set class to be applied to ellipses "pages" for CSS targetting
				this.ellipsesClass = this.options.ellipsesClass || "ellipses";

				// Set class to be applied to pages not containing a link
				// NOTE: Used to apply padding evenly to both links and non-links
				this.noLinkClass = this.options.noLinkClass || "no-link";

				// Set param to be used when forming page URLs
				this.pageParam = this.options.pageParam || "page";

				// Set view to be used when rendering pages
				this.pageView = this.options.pageView || PageView;

				// Set container
				this.container = this.$( ".pagination-container" );

				// Set arrow variables
				this.leftArrow = this.$( ".arrow.left" );
				this.leftArrowLink = this.leftArrow.find( "a" );
				this.rightArrow = this.$( ".arrow.right" );
				this.rightArrowLink = this.rightArrow.find( "a" );

				// Set collection
				this.collection = new Backbone.Collection( [], { model : Page } );
			},

			setListeners : function() {
				// Listen to collection events
				this.listenTo( this.collection, "reset", this.collectionReset );
				this.listenTo( this.collection, "add", this.collectionAdd );
				this.listenTo( this.collection, "remove", this.collectionRemove );
			},

			setLowestHighest : function() {
				// Set lowest/highest pages
				this.state.lowest = this.state.pages.length ? this.state.pages[ 0 ] : 0;
				this.state.highest = this.state.pages.length ? this.state.pages[ this.state.pages.length - 1 ] : 0;
			},

			setTotalPages : function() {
				// Calculate total pages
				this.state.total = this.state.items >= this.state.itemsPerPage ?
					Math.ceil( this.state.items / this.state.itemsPerPage ) : 1;
			},
			// End setter functions

			// Begin collection functions
			collectionReset : function() {
				this.collection.each( this.collectionAdd, this );
			},

			collectionAdd : function( model ) {
				// Set up page view
				var view = new this.pageView( {
					model : model,
				} );

				// Append page to container
				this.container.append( view.render().el );

				// Set view listeners
				view.on( "pageClicked", this.onPageClicked.bind( this, view ) );

				// Check collection length
				this.checkCollectionLength();
			},

			collectionRemove : function() {
				// Check collection length
				this.checkCollectionLength();
			},

			checkCollectionLength : function() {
				// Log check
				this.logger.log( "Checking collection length: ", this.collection.length );

				// Add active class if there are one or more pages
				this.$el.toggleClass( this.activeClass, this.collection.length > 0 );
			},
			// End collection functions

			// Begin event listener functions
			onArrowClicked : function( e ) {
				// Store target
				var link = $( e.currentTarget ).find( "a" ),
				regExp = new RegExp( this.pageParam + "=(\\d+)" ), // Ex: /page=(\d+)/
				matches = link[ 0 ].href.match( regExp );

				// Prevent event actions
				Utils.preventEventActions( e );

				if ( !matches || !matches.length ) {
					return;
				}

				// Announce page change to channel
				Utils.sendChannelMessage( this.channel, "pageChanged", matches[ 1 ] );
			},

			onPageClicked : function( view ) {
				// Announce page change to channel
				Utils.sendChannelMessage( this.channel, "pageChanged", view.model.get( "page" ) || this.state.current );
			},
			// End event listener functions

			// Begin calculation functions
			calculateState : function() {
				// Calculate group
				this.calculateGroup();

				// Calculate ellipses
				this.calculateEllipses();

				// Set arrow states
				this.setArrowStates();
			},

			calculateGroup : function() {
				// Log group calculation
				this.logger.log( "Calculating group" );

				// Total pages is less than or equal to group size
				if ( this.state.total <= this.groupSize ) {
					this.handleTotalSmallerThanGroupSize();

					return;
				}

				// Current page is 1
				if ( this.state.current === 1 ) {
					this.handleCurrentEqualsOne();

					return;
				}

				// Current page is the "top" page
				if ( this.state.current === this.state.total ) {
					this.handleCurrentEqualsTotal();

					return;
				}

				// Handle left/right sides
				this.handleLeft();
				this.handleRight();

				// Pages need a rebalance (the right side couldn't be "filled")
				if ( this.state.rebalance === true ) {
					this.handleRebalance();
				}
			},

			calculateEllipses : function() {
				// Log ellipses calculation
				this.logger.log( "Calculating ellipses" );

				// Handle left ellipses
				this.handleLeftEllipses();

				// Handle right ellipses
				this.handleRightEllipses();
			},

			setArrowStates : function() {
				// Log arrow state setting
				this.logger.log( "Setting arrow states" );

				// Handle left arrow
				this.handleLeftArrow();

				// Handle right arrow
				this.handleRightArrow();
			},
			// End calculation functions

			// Begin group handlers
			handleLeft : function() {
				// Log handler
				this.logger.log( "Handling left side" );

				// Loop variables
				var i = this.state.current - this.state.left;

				// Left side can't be "filled", shift remainder to the right side
				if ( this.state.current - this.state.left < 1 ) {
					this.state.right += this.state.left - ( this.state.current - 1 );

					// Reset iterator
					i = 1;
				}

				// Push pages
				this.pushPages( i, this.state.current );
			},

			handleRight : function() {
				// Log handler
				this.logger.log( "Handling right side" );

				// Loop variables
				var i = this.state.current + 1,
				len = this.state.current + this.state.right;

				// Right side can't be "filled", shift remainder to the left side
				if ( this.state.current + this.state.right > this.state.total ) {
					// Set rebalance flag
					this.state.rebalance = true;

					// Reset length
					len = this.state.total;
				}

				// Push pages
				this.pushPages( i, len );
			},

			handleRebalance : function() {
				// Log handler
				this.logger.log( "Rebalancing" );

				// Remaining pages to be added
				var remainder = this.groupSize - this.state.pages.length,
				temp = []; // Temp array to store pages

				// Left side can't handle the entire remainder, reset to available page space
				if ( this.state.lowest - remainder < 1 ) {
					remainder = this.state.lowest - 1;
				}

				// Push left side pages to temp array
				for ( var i = this.state.lowest - remainder, len = this.state.lowest; i < len; i++ ) {
					temp.push( i );
				}

				// Prepend temp array to pages
				this.state.pages = temp.concat( this.state.pages );

				// Reset lowest/highest
				this.setLowestHighest();
			},

			handleCurrentEqualsOne : function() {
				// Log handler
				this.logger.log( "Handling current equals one special case" );

				// Push pages
				this.pushPages( 1, this.groupSize );
			},

			handleCurrentEqualsTotal : function() {
				// Log handler
				this.logger.log( "Handling current equals total special case" );

				// Push pages
				this.pushPages( this.state.total - this.groupSize + 1, this.state.total );
			},

			handleTotalSmallerThanGroupSize : function() {
				// Log handler
				this.logger.log( "Handling total being less than or equals to group size special case" );

				// Push pages
				this.pushPages( 1, this.state.total );
			},
			// End group handlers

			// Begin ellipses handlers
			handleLeftEllipses : function() {
				// Log handler
				this.logger.log( "Handling left ellipses" );

				// Ellipses and/or supplemental pages are not needed on the left side
				if ( this.state.lowest === 1 ) {
					return false;
				}

				// Temp array for pages
				// NOTE: Left side should always contain 1
				var temp = [ 1 ];

				// Lowest page is greater than three, indicating ellipses are needed
				if ( this.state.lowest > 3 ) {
					temp.push( this.ellipsesText );
				// Lowest page is 3, indicating a special case where 2 should be added as a page
				} else if ( this.state.lowest === 3 ) {
					temp.push( 2 );
				}

				// Prepend temp array to pages
				this.state.pages = temp.concat( this.state.pages );

				// Reset lowest/highest
				this.setLowestHighest();
			},

			handleRightEllipses : function() {
				// Log handler
				this.logger.log( "Handling right ellipses" );

				// Ellipses and/or supplemental pages are not needed on the right side
				if ( this.state.highest === this.state.total ) {
					return false;
				}

				// Highest page is 3 or more removed from the "top" page, indicating ellipses are needed
				if ( this.state.highest + 2 < this.state.total ) {
					this.state.pages.push( this.ellipsesText );
				// Highest page is 2 removed from the "top" page, indicating a special case where (top page - 1) should be added as a page
				} else if( this.state.total - 2 === this.state.highest ) {
					this.state.pages.push( this.state.total - 1 );
				}

				// Add "top" page
				this.state.pages.push( this.state.total );

				// Reset lowest/highest
				this.setLowestHighest();
			},
			// End ellipses handlers

			// Begin arrow handlers
			handleLeftArrow : function() {
				// Log handler
				this.logger.log( "Handling left arrow" );

				// Check for arrow length
				if ( !this.leftArrow.length ) {
					return false;
				}

				// Left arrow is active when the current page is greater than 1
				this.leftArrow.toggleClass( this.activeClass, this.state.current > 1 );

				// Set left arrow link
				this.leftArrowLink[ 0 ].href = this.leftArrow.hasClass( this.activeClass ) ?
					this.formPageUrl( this.state.current - 1 ) : "#";

				// Log active and link
				this.logger.log( "Left arrow is active: ", this.leftArrow.hasClass( this.activeClass ) );
				this.logger.log( "Left arrow link: ", this.leftArrowLink[ 0 ].href );
			},

			handleRightArrow : function() {
				// Log handler
				this.logger.log( "Handling right arrow" );

				// Check for arrow length
				if ( !this.rightArrow.length ) {
					return false;
				}

				// Right arrow is active when the current page is less than the "top" page
				this.rightArrow.toggleClass( this.activeClass, this.state.current < this.state.total );

				// Set left arrow link
				this.rightArrowLink[ 0 ].href = this.rightArrow.hasClass( this.activeClass ) ?
					this.formPageUrl( this.state.current + 1 ) : "#";
			},
			// End arrow handlers

			// Begin utility functions
			formPageUrl : function( page ) {
				// Default URL params to send when forming URLs
				var params = {};

				// Add page param to URL params
				params[ this.pageParam ] = page;

				return Utils.formUrlWithParams( this.state.url, params );
			},

			getFreshState : function() {
				return {
					"current" : 0,
					"highest" : 0,
					"items" : 0,
					"itemsPerPage" : 0,
					"left" : 0,
					"lowest" : 0,
					"pages" : [],
					"rebalance" : false,
					"right" : 0,
					"split" : 0,
					"total" : 0,
					"url" : window.location.pathname,
				};
			},

			mapDataAttributes : function() {
				// Log mapping
				this.logger.log( "Mapping attributes to state" );

				// Map element data attributes
				_.mapObject( {
					"current" : "data-current-page",
					"items" : "data-total-items",
					"itemsPerPage" : "data-items-per-page",
				}, function( value, key ) {
					// Set value on state
					this.state[ key ] = this.$el.attr( value ) ? parseInt( this.$el.attr( value ), 10 ) : this.state[ key ];
				}.bind( this ) );
			},

			pagesToModels : function() {
				// Temp array to be returned
				var temp = [],
				i = 0, len = this.state.pages.length; // Loop variables

				for( ; i < len; i++ ) {
					var page = this.state.pages[ i ],
					// Model attributes
					attributes = {
						classes : [],
						isLink : page !== this.ellipsesText && page !== this.state.current,
						page : page,
					};

					// If the page is a link, add URL attributes
					if ( attributes.isLink ) {
						attributes.url = this.formPageUrl( page );
					}

					// Add ellipses class if needed
					if ( page === this.ellipsesText ) {
						attributes.classes.push( this.ellipsesClass );
					}

					// Add current class if needed
					if ( page === this.state.current ) {
						attributes.classes.push( this.currentClass );
					}

					// Add no-link class if needed
					if ( attributes.isLink === false ) {
						attributes.classes.push( this.noLinkClass );
					}

					// Join classes if needed
					attributes.classes = attributes.classes.length ? attributes.classes.join( " " ) : "";

					temp.push( attributes );
				}

				// Log page forming
				this.logger.log( "Page attributes formed: ", temp );

				return temp;
			},

			pushPages : function( i, len ) {
				// Log push arguments
				this.logger.log( "Pushing pages with i: " + i + " and len: " + len );

				// Push pages based on loop variable arguments
				for( ; i <= len; i++ ) {
					this.state.pages.push( i );
				}

				// Reset lowest/highest
				this.setLowestHighest();
			},

			refresh : function() {
				// Log refresh call
				this.logger.log( "Refreshing" );

				// Set URL override if needed
				this.state.url = this.$el.attr( "data-url-override" ) && this.$el.attr( "data-url-override" ).length ?
					this.$el.attr( "data-url-override" ) : this.state.url;

				// Set split
				this.state.split = ( this.groupSize - 1 ) / 2;

				// Set left/right sides
				this.state.left = this.state.split;
				this.state.right = this.state.split;

				// Reset total pages
				this.setTotalPages();
			}
			// End utility functions
		} );
	}
);
