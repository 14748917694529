define(

	function() {
		"use strict";

		/**
		 * A shared module that is used to set-up Google Analytics event tracking in MediaOS.
		 * The module uses data attributes to set up event listeners on elements in a way that can be
		 * tracked in the Google Analytics dashboard.
		 *
		 * Available data attributes are
		 * `data-event-type`  - The user action to listen for, defaults to "click"
		 * `data-ga-category` - Specifies the event category, REQUIRED
		 * `data-ga-action`   - Specifies the event action, REQUIRED
		 * `data-ga-label`    - Specifies the event label
		 *
		 * To add tracking to an element in the markup, the element must have a data attribute
		 * of "ga-category".
		 *
		 * For example, if click tracking is desired on the xcreate new link,
		 * we can add it to the mark-up like so:
		 *
		 *     <a href="/en/create-new/content" data-ga-category="Main Navigation" data-ga-action="Create New Click" data-ga-label="/en/content/index">Create New</a>
		 *
		 * For more information on naming conventions and best practices, see:
		 * @see {@link https://support.google.com/analytics/answer/1033068?hl=en}
		 * @see {@link https://developers.google.com/analytics/devguides/collection/analyticsjs/events|GA Events}
		 *
		 * @class Ga
		 * @type {object}
		 */
		return {
			init: function( selector ) {
				if ( typeof ga === "undefined"  ) {
					return;
				}

				// The google analytics object, set from the window
				this.ga = ga;

				// start time for duration functions
				this.pageHitTime = Date.now();

				// The selector used to target elements
				selector = selector || "[data-ga-category]:not([data-ga-category=''])";

				// Find all of the elements by selector and add an event listener to each
				document
					.querySelectorAll( selector )
					.forEach( this.addListener.bind( this ) );
			},

			/**
			 * Adds the onUserAction as an event handler to the passed in DOM element
			 *
			 * @param {Element} el The DOM element to add the listener to
			 *
			 * @see {@link https://developers.google.com/analytics/devguides/collection/analyticsjs/events|GA Events}
			 */
			addListener: function( el ) {
				// The ga fields object, for more information on fields, see:
				var fields = {
					eventCategory: el.getAttribute( "data-ga-category" ),
					eventAction: el.getAttribute( "data-ga-action" ),
					eventLabel: el.getAttribute( "data-ga-label" ),
					transport: "beacon", // NOTE: Forcing a beacon until this is the default
				},

				// The type of user event that will be listened for
				eventType = el.getAttribute( "data-event-type" ) || "click";

				// categories and actions are required
				// Do not add the listener if these are not defined
				if ( fields.eventCategory && fields.eventAction ) {
					el.addEventListener( eventType, this.onUserAction.bind( this, fields ), {passive: true} );
				}
			},

			/**
			 * Handles the user action and fires the ga send
			 *
			 * @see {@link https://developers.google.com/analytics/devguides/collection/analyticsjs/events|GA Events}
			 *
			 * @param  {Object} fields The fields object used to properly segment the event in GA
			 */
			onUserAction: function( fields ) {
				this.send({
					...fields,
					hitType: 'event'
				});
			},

			/**
			 * Very generic send action for GA - can be used for other hitTypes besides "event"
			 *
			 * @see {@link https://developers.google.com/analytics/devguides/collection/analyticsjs/events}
			 *
			 * @param  {Object} fields The fields object used to properly segment the event in GA
			 */
			send: function(fields) {
				if ( this.ga && typeof this.ga === "function" && fields) {
					this.ga('send', fields);
					return fields;
				} else {
					return null;
				}
			},

			/**
			 * Log milliseconds since a specified performance.mark() event
			 *
			 * @param {String} performanceMark Label of performance.mark()
			 * @param {Object} fields GA fields
			 * @param {String} fields.timingCategory A string for categorizing all user timing variables into logical groups
			 * @param {String} fields.timingLabel A string that can be used to add flexibility in visualizing user timings in the reports
			 *
			 */
			sendLoadTiming(performanceMark, fields) {
				const defaultFields = {
					hitType: 'timing',
					timingVar: 'load'
				};
				const perf = window.performance;
				let measure;

				// timingCategory and timingLabel are required
				if (!performanceMark || !fields.timingCategory || !fields.timingLabel) {
					return null;
				}

				// Return timing from when DOM first starts loading (i.e. after TTFB)
				if (performanceMark === 'dom-loading') {
					// Calculate time since DOM started loading
					measure = Math.round(perf.now());
				} else {
					// If mark exists, calculate time taken since that mark
					const marks = perf.getEntriesByName(performanceMark);
					measure = marks.length ? Math.round(perf.now() - marks[0].startTime) : null;
				}

				if (measure) {
					const gaFields = {
						...defaultFields,
						...fields,
						timingValue: Math.round(measure),
					};

					// Log time until buttons are interactive since DOM loading
					this.send(gaFields);

					return gaFields;
				} else {
					return null;
				}
			},

			/**
			 * Custom event called after all loading, including ajax calls,
			 * finishes on initial content edit page load
			 */
			eventPageFullyLoaded: function () {
				const end_load = Date.now();
				const duration = end_load - this.pageHitTime;

				const fields = {
					hitType: 'event',
					eventCategory: 'content edit',
					eventAction: 'page fully loaded',
					eventLabel: 'duration of full page load',
					eventValue: duration,
				};

				this.send(fields);
			}
		};
	}
);
