define(
	// Define module dependencies
	[
		"jquery",
		"utils",
	],

	function( $, Utils ) {
		"use strict";

		/**
		 * Ajaxer is a shared module that can be used to quickly abstract AJAX operations to keep other modules more DRY
		 *
		 * @typedef {Object} Ajaxer
		 */
		return {

			/**
			 *
			 * @param {object} opts
			 * @param {function} callback
			 *
			 * @returns {Promise<any>}
			 */
			run : function( opts, callback ) {
				// Format callback with fallback if needed
				callback = Utils.errorCheckCallback( callback );

				// Remove an els that exist in data
				// NOTE: This is more relevant to editable listing pages
				if ( opts.data && opts.data[ "_el" ] ) {
					delete opts.data[ "_el" ];
				}

				return new Promise( function( resolve, reject ) {
					$.ajax( opts )
					.fail( function( jqXHR, textStatus ) {
						reject( callback( true, [ jqXHR.responseJSON, textStatus, jqXHR ] ) );
					}.bind( this ) )
					.done( function( data ) {
						// run callback for backwards compatibility
						// NOTE: Yii may return a 200 when there's actually an error
						// Check for status code to be in the 200s
						callback( !data.code || ( data.code < 200 || data.code >= 300 ), arguments );

						// resolve with the ajax response arguments array
						resolve( arguments );
					} );
				} );
			}
		};
	}
);
